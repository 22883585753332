import { Parking } from "../../models/properties/Parking";
import { Localizer } from "../../services/Localizer";

export class ParkingViewModel {

    title: string

    showSale = false
    saleTitleText = ''
    salePriceText = ''
    saleAdditionalTexts: string[] = []

    showRental = false
    rentalTitleText = ''
    rentalPriceText = ''
    rentalAdditionalTexts: string[] = []

    constructor(parking: Parking, localizer: Localizer) {

        this.title =`${localizer.k('property.label.parking_' + parking.type)} ${localizer.k('prep.in')} ${parking.property.public_name}`

        if (parking.is_for_sale) {
            this.showSale = true
            this.saleTitleText = localizer.k('property.label.parking_sale.price')

            if (parking.sale_price) {
                this.salePriceText = Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(parking.sale_price)
            } else {
                this.salePriceText = localizer.k('property.label.sale.price_consult')
            }

            if (parking.sale_included_in_price) {
                this.saleAdditionalTexts.push(localizer.k('property.label.parking_sale.included'))
            }

            if (parking.sale_only_available_with_home) {
                this.saleAdditionalTexts.push(localizer.k('property.label.parking_sale.only_inclusive'))
            }
        }

        if (parking.is_for_rental) {
            this.showRental = true
            this.rentalTitleText = localizer.k('property.label.parking_rental.price')

            if (parking.rental_price_one_week) {
                this.rentalPriceText = Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(parking.rental_price_one_week)
            }

            if (parking.is_rental_conditional_availability) {
                this.rentalAdditionalTexts.push(localizer.k('property.label.parking_rental.conditional_availability'))
            }

            if (parking.rental_only_available_with_home) {
                this.rentalAdditionalTexts.push(localizer.k('property.label.parking_rental.only_inclusive'))
            }

        }
    }

    
}