import React, { useContext, useEffect, useState } from 'react';
import { AppDataContext } from '../../App';
import SearchForm from '../../components/search-form/SearchForm';
import SearchList from '../../components/search-list/SearchList';
import SearchMap from '../../components/search-map/SearchMap';
import { PropertyListing } from '../../models/list-data/PropertyListing';

function PropertyList() {

    const appContext = useContext(AppDataContext)
    const [propertyListings, setPropertyListings] = useState<PropertyListing[]>([])

    useEffect(()=>{
        let listings = appContext.modelData.allListings()
        let season = appContext.modelData.findSeason()

        if (season != null){
            setPropertyListings(listings.filter(l => {
                return l.matchesSearch(appContext.search.searchFunction, appContext.search.searchPropertyType, appContext.search.searchPropertySubtype, season!) 
            }))
        }

    }, [appContext.modelData, appContext.search, setPropertyListings])

    return (
        <div className='PropertyList'>
            <div className='SearchFormContainer'>
                <SearchForm useSearchButton={false} useAdvancedFilter={false} onSearchSelectionChanged={() => {}}/>
            </div>
            <div className='flex flex-wrap -mx-4 -mb-4 md:mb-0 mt-2'>
                <div className='w-full lg:w-2/3 px-4 mb-4 md:mb-0 height-limited'>
                    <SearchList propertyListings={propertyListings}/>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-4 md:mb-0 height-limited hidden lg:block'>
                    <SearchMap propertyListings={propertyListings}/>
                </div>
            </div>
        </div>
    );
}

export default PropertyList;
