import React, { useContext, useEffect, useState } from 'react';
import { AppDataContext, LocalizationContext } from '../../App';
import { PropertyListing } from '../../models/list-data/PropertyListing';
import { MapCoordinates } from '../../models/shared/MapCoordinates';
import Map from '../map/Map';
import { MapMarkerProps } from '../map/MapMarker';
import { MapMarkerType } from '../map/MapMarkerType';

interface SearchMapProps {
    propertyListings: PropertyListing[]
}

function SearchMap(props: SearchMapProps) {
    const appContext = useContext(AppDataContext);
    const loc = useContext(LocalizationContext).localizer

    const [markerProps, setMarkerProps] = useState<MapMarkerProps[]>([])

    useEffect(() => {
        let markerData: MapMarkerProps[] = []

        if (appContext.modelData.mapItems.pointsOfInterest) {
            markerData = markerData.concat(appContext.modelData.mapItems.pointsOfInterest.map(poi => {
                return { type: MapMarkerType.poi, subtype: poi.type, title: poi.name_spanish, location: poi.location, isPrimary: false }
            }))
        }

        markerData = markerData.concat(
            props.propertyListings.filter(l => {return l.mapMarkerType != null && l.mapCoordinates != null})
            .map(l => {
                return { 
                    type: l.mapMarkerType!, 
                    subtype: undefined, 
                    title:l.computedTitle(loc), 
                    location: l.mapCoordinates!, 
                    isPrimary: true
                }
        }))
        
        setMarkerProps(markerData)
        
    }, [appContext.modelData.mapItems.pointsOfInterest, props.propertyListings])

    function updateMapCenter(newCenter: MapCoordinates) {
        if (appContext.searchMapCenter.latitude !== newCenter.latitude || appContext.searchMapCenter.longitude !== newCenter.longitude || appContext.searchMapCenter.zoom !== newCenter.zoom) {
            appContext.setSearchMapCenter(newCenter)
        }
    }

    return <div className='SearchMap w-full h-full'>
        <Map center={appContext.searchMapCenter} markers={markerProps} adjustToMarkers={true} onMapMoved={loc => { updateMapCenter(loc) }} />

    </div>;
}

export default SearchMap;
