import { ModelData } from "../../view-models/model-data/ModelData";

interface TableRow {
    refNo: number

    propType: string
    propName: string
    propAttrs: string

    homeBedrooms: string

    propHasSeason: boolean
    propIsForSale: boolean
    propIsForRentalGeneral: boolean
    propIsForRentalEaster: boolean

    propIsVisible: boolean

    propHasLat: boolean
    propHasLon: boolean

    propImageCount: number
    id: string

    viewLink: string
    adminLink: string
}

export class AdminTableViewModel {

    rows: TableRow[] = []

    constructor(modelData: ModelData, linkHost: string) {
        this.processHomes(modelData, linkHost)
        this.rows = this.rows.sort((a, b) => { return a.refNo - b.refNo })
    }

    processHomes(modelData: ModelData, linkHost: string) {
        if (modelData.properties.homes == null) {
            return
        }

        for (let home of modelData.properties.homes) {

            const season = modelData.findSeason()
            const homeSeason = season != null ? home.sale_and_rental_seasons.filter((s) => { return s.season === season.id })[0] : null

            const propHasSeason = homeSeason != null
            const propIsForSale = homeSeason != null ? homeSeason.is_for_sale : false
            const propIsForRentalGeneral = homeSeason != null ? homeSeason.is_for_rental_general : false
            const propIsForRentalEaster = homeSeason != null ? homeSeason.is_for_rental_easter : false

            const propIsVisible = propIsForSale || propIsForRentalGeneral || propIsForRentalEaster

            const viewLink = `${propIsForSale ? 'sale' : 'rental'}/home/all/${home.property.ref_number}`

            let extraAttrs = [
                home.home_general.is_apartment ? 'Apt' : '',
                home.home_general.is_villa ? 'Villa' : '',
                home.home_general.is_duplex ? 'Duplex' : '',
                home.home_general.is_bungalow ? 'Bungalow' : '',
                home.home_general.is_attic ? 'Attic' : ''
            ]

            if (home.home_general.is_apartment) {
                extraAttrs.push(home.home_general.floor)
            }

            this.rows.push({
                refNo: parseInt(home.property.ref_number),

                propType: 'Home',
                propName: home.property.public_name,
                propAttrs: extraAttrs.join(' '),

                homeBedrooms: home.home_general.bedrooms,

                propHasSeason: propHasSeason,
                propIsForSale: propIsForSale,
                propIsForRentalGeneral: propIsForRentalGeneral,
                propIsForRentalEaster: propIsForRentalEaster,
                propIsVisible: propIsVisible,

                propHasLat: home.location.latitude != null,
                propHasLon: home.location.longitude != null,

                propImageCount: home.media.photos.length,


                id: home.id,

                viewLink: viewLink,
                adminLink: `${linkHost}/admin/plugins/content-manager/collectionType/application::home.home/${home.id}`
            })
        }
    }

}