import React from 'react';
import SVG from 'react-inlinesvg';
import { IconStyle } from './IconStyle';
import { IconType, urlForIconType } from './IconType';
import Placeholder from './Placeholder';

export interface IconProps {
    style: IconStyle
    type: IconType
}

function Icon(props: IconProps) {
    return <SVG className={`Icon h-${props.style.size} w-${props.style.size} text-${props.style.color}-${props.style.intensity}`} src={urlForIconType(props.type)} />
}

export default Icon;
