import { SearchFunction } from './SearchFunction';

export enum SearchPropertyType {
    Home = 'home',
    Commercial = 'commercial',
    Parking = 'parking',
    Plot = 'plot'
}

export const AllSearchPropertyTypes = [SearchPropertyType.Home, SearchPropertyType.Commercial, SearchPropertyType.Parking, SearchPropertyType.Plot];

export function searchPropertyTypesForSearchFunction(func: SearchFunction): SearchPropertyType[] {
    // .Sale
    if (func === SearchFunction.Sale) {
        return [SearchPropertyType.Home, SearchPropertyType.Commercial /*, SearchPropertyType.Parking, SearchPropertyType.Plot */];

        // .Rental
    } else if (func === SearchFunction.Rental) {
        return [SearchPropertyType.Home, SearchPropertyType.Commercial, /*SearchPropertyType.Parking*/];
        // .All
    } else {
        return [SearchPropertyType.Home, SearchPropertyType.Commercial, /*SearchPropertyType.Parking, SearchPropertyType.Plot*/];
    }
}
