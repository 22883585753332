import BathIcon from '../../assets/svg/property/bath-solid.svg';
import BedIcon from '../../assets/svg/property/bed-solid.svg';
import ElevatorIcon from '../../assets/svg/property/elevator-solid.svg';
import RulerIcon from '../../assets/svg/property/ruler-solid.svg';
import TolietIcon from '../../assets/svg/property/toilet-solid.svg';
import HomeIcon from '../../assets/svg/property/home-solid.svg';
import ClipboardIcon from '../../assets/svg/property/clipboard-check-solid.svg';
import BeachIcon from '../../assets/svg/property/umbrella-beach-solid.svg';
import WheelchairIcon from '../../assets/svg/property/wheelchair-solid.svg'; 
import WaterIcon from '../../assets/svg/property/water-solid.svg';
import LeafIcon from '../../assets/svg/property/spa-solid.svg';
import CalendarIcon from '../../assets/svg/property/calendar-alt-regular.svg';
import HandshakeIcon from '../../assets/svg/property/handshake-regular.svg';
import CompassIcon from '../../assets/svg/property/compass-regular.svg';
import BriefcaseIcon from '../../assets/svg/property/briefcase-solid.svg'
import CashRegisterIcon from '../../assets/svg/property/cash-register-solid.svg'
import DoorOpenIcon from '../../assets/svg/property/door-open-solid.svg'
import StoreIcon from '../../assets/svg/property/store-solid.svg'
import CutleryIcon from '../../assets/svg/property/utensils-solid.svg'
import CoffeeIcon from '../../assets/svg/property/coffee-solid.svg'

export enum IconType {
    bed = "bed",
    meters = "meters",
    bathroom = "bathroom",
    halfbath = "halfbath",
    elevator = "elevator",
    home = "home",
    clipboard = "clipboard",
    beach = "beach",
    wheelchair = "wheelchair",
    water = "water",
    leaf = "leaf",
    calendar = "calendar",
    handshake = "handshake",
    compass = "compass",
    briefcase = "briefcase",
    cashRegister = "cashRegister",
    doorOpen = "doorOpen",
    store = "store",
    cutlery = "cutlery",
    coffee = "coffee"
}

export function urlForIconType(iconType: IconType): string {
    switch (iconType) {
        case IconType.bed: return BedIcon;
        case IconType.meters: return RulerIcon;
        case IconType.bathroom: return BathIcon;
        case IconType.halfbath: return TolietIcon;
        case IconType.elevator: return ElevatorIcon;
        case IconType.home: return HomeIcon;
        case IconType.clipboard: return ClipboardIcon;
        case IconType.beach: return BeachIcon;
        case IconType.wheelchair: return WheelchairIcon;
        case IconType.water: return WaterIcon;
        case IconType.leaf: return LeafIcon;
        case IconType.calendar: return CalendarIcon;
        case IconType.handshake: return HandshakeIcon;
        case IconType.compass: return CompassIcon;
        case IconType.briefcase: return BriefcaseIcon;
        case IconType.cashRegister: return CashRegisterIcon;
        case IconType.doorOpen: return DoorOpenIcon;
        case IconType.store: return StoreIcon;
        case IconType.cutlery: return CutleryIcon;
        case IconType.coffee: return CoffeeIcon;
    }
}
