import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer';
import Navigation from './components/navigation/Navigation';
import PageWTitle from './components/page-w-title/PageWTitle';
import { PointOfInterest as PointOfInterestModel } from './models/map-data/PointOfInterest';
import { Home as HomeModel } from './models/properties/Home';
import { Commercial as CommercialModel } from './models/properties/Commercial';
import { SearchFunction } from './models/search/SearchFunction';
import { SearchPropertyType } from './models/search/SearchPopertyType';
import { SearchPropertySubtype } from './models/search/SearchPropertySubtype';
import { MapCoordinates } from './models/shared/MapCoordinates';
import { Season as SeasonModel } from './models/shared/Season';
import { langLocalizer } from './services/Localizer';
import { ModelListFetcher } from './services/ModelListFetcher';
import { ModelData } from './view-models/model-data/ModelData';
import { SearchViewModel } from './view-models/search-view-model/SearchViewModel';
import Home from './views/home/Home';
import PropertyDetails from './views/property-details/PropertyDetails';
import PropertyList from './views/property-list/PropertyList';
import AdminTable from './views/admin-table/AdminTable'
import LanguageDetector from 'i18next-browser-languagedetector';
import RentalConditions from './views/rental-conditions/RentalConditions';

const languageDetector = new LanguageDetector({ languageUtils: { getBestMatchFromCodes: true } }, {
    order: ['localStorage', 'sessionStorage', 'navigator'],
    caches: ['localStorage', 'sessionStorage']
})

function getLanguageCode(): 'en' | 'es' {
    return ((languageDetector.detect() as unknown as string[])
        .map(c => { return c.toLowerCase().substring(0, 2) })
        .filter(c => { return c.startsWith('en') || c.startsWith('es') })[0] ?? 'es') as 'en' | 'es'
}

function storeLanguageCode(code: 'en'|'es') {
    languageDetector.cacheUserLanguage(code)
}

// storeLanguageCode(getLanguageCode())

const SearchMapCenter: MapCoordinates = { latitude: 37.7049546, longitude: -0.7227662, zoom: 12.1 }
const assetLocation = window.location.hostname === 'localhost' ? 'http://37.139.15.91:1337' : `${window.location.protocol}//${window.location.host}`

export const AppDataContext = createContext({ search: SearchViewModel.default(), modelData: ModelData.default(), searchMapCenter: SearchMapCenter, setSearchMapCenter: (center: MapCoordinates) => { } });
export const LocalizationContext = createContext({ localizer: langLocalizer(getLanguageCode()) })
export const LinkLocationContext = createContext({ link: assetLocation })

const ModelFetcher = new ModelListFetcher(assetLocation, '')

function App() {

    const [searchParams, setSearchParams] = useState(SearchViewModel.default())
    const [searchMapCenter, setSearchMapCenter] = useState<MapCoordinates>(SearchMapCenter)
    const [modelData, setModelData] = useState(ModelData.default())

    const [,forceUpdate] = useReducer(x => x+1, 0)


    const locationFunctionTypeSubtypeAndProperty = useRouteMatch<{
        searchFunc?: string;
        type?: string;
        subtype?: string;
        propertyId?: string;
    }>({
        path: '/:searchFunc?/:type?/:subtype?/:propertyId?'
    });


    let updatedSearchParams = searchParams.clone()

    if (locationFunctionTypeSubtypeAndProperty && locationFunctionTypeSubtypeAndProperty.params.searchFunc) {
        const sf = locationFunctionTypeSubtypeAndProperty.params.searchFunc as SearchFunction
        if (sf !== searchParams.searchFunction) {
            updatedSearchParams = updatedSearchParams.settingSearchFunction(sf)
        }
    }

    if (locationFunctionTypeSubtypeAndProperty && locationFunctionTypeSubtypeAndProperty.params.type) {
        const st = locationFunctionTypeSubtypeAndProperty.params.type as SearchPropertyType
        if (st !== searchParams.searchPropertyType) {
            updatedSearchParams = updatedSearchParams.settingSearchPropertyType(st)
        }
    }

    if (locationFunctionTypeSubtypeAndProperty && locationFunctionTypeSubtypeAndProperty.params.subtype) {
        const sst = locationFunctionTypeSubtypeAndProperty.params.subtype as SearchPropertySubtype
        if (sst !== searchParams.searchPropertySubtype) {
            updatedSearchParams = updatedSearchParams.settingSearchPropertySubtype(sst)
        }
    }

    if (!updatedSearchParams.equals(searchParams)) {
        setSearchParams(updatedSearchParams)
    }

    useEffect(() => {
        if (modelData.properties.homes != null && modelData.mapItems.pointsOfInterest != null) {
            return
        }

        Promise.all([
            ModelFetcher.fetch<PointOfInterestModel>('/tourist-locations'),
            ModelFetcher.fetch<CommercialModel>('/commercial-properties'),
            ModelFetcher.fetch<HomeModel>('/homes'),
            ModelFetcher.fetch<SeasonModel>('/seasons')
        ]).then(fields => {
            const [pointsOfInterest, commercials, homes, seasons] = fields
            setModelData(modelData.settingPointsOfInterest(pointsOfInterest).settingCommercials(commercials).settingHomes(homes).setttingSeasons(seasons))
        })
    }, [modelData, setModelData])

    function updateMapCenter(newCenter: MapCoordinates) {
        if (searchMapCenter.zoom !== newCenter.zoom || searchMapCenter.latitude !== newCenter.latitude || searchMapCenter.longitude !== newCenter.longitude) {
            setSearchMapCenter(newCenter)
        }
    }

    function toggleLanguage() {
        console.log('Updating')
        storeLanguageCode(getLanguageCode() == 'es' ? 'en' : 'es')
        forceUpdate()
    }

    return (
        <div className='App antialiased bg-body text-body font-body w-full xl:container mx-auto px-4'>
            <LinkLocationContext.Provider value={{ link: assetLocation }}>
                <LocalizationContext.Provider value={{ localizer: langLocalizer(getLanguageCode()) }} >
                    <Navigation onLanguageChange={toggleLanguage} />
                    <AppDataContext.Provider
                        value={{ search: searchParams, modelData: modelData, searchMapCenter: searchMapCenter, setSearchMapCenter: updateMapCenter }}>
                        <Switch>
                            <Route path='/admin-table'>
                                <AdminTable />
                            </Route>
                            <Route path='/policy/rental'>
                                <PageWTitle title='Inmobiliaria Euromar - Rental Conditions'>
                                    <RentalConditions />
                                </PageWTitle>
                            </Route>
                            <Route path='/:searchFunc/:type/:subtype/:propertyId'>
                                <PageWTitle title='Inmobiliaria Euromar - Property'>
                                    <PropertyDetails refNumber={locationFunctionTypeSubtypeAndProperty?.params.propertyId ?? '1234'} />
                                </PageWTitle>
                            </Route>
                            <Route path='/:searchFunc/:type?/:subtype?'>
                                <PageWTitle title='Inmobiliaria Euromar - Search'>
                                    <PropertyList />
                                </PageWTitle>
                            </Route>
                            <Route path='/'>
                                <PageWTitle title='Inmobiliaria Euromar - Home'>
                                    <Home />
                                </PageWTitle>
                            </Route>
                        </Switch>
                    </AppDataContext.Provider>
                    <Footer />
                </LocalizationContext.Provider>
            </LinkLocationContext.Provider>
        </div>
    );
}

export default App;
