
import React, { useContext, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { AppDataContext, LocalizationContext } from '../../App';
import CityIcon from '../../assets/svg/property/city-solid.svg';
import CompassIcon from '../../assets/svg/property/compass-regular.svg';
import MapIcon from '../../assets/svg/property/map-marker-alt-solid.svg';
import RulerIcon from '../../assets/svg/property/ruler-solid.svg';
import FlowerIcon from '../../assets/svg/property/spa-solid.svg';
import WaterIcon from '../../assets/svg/property/water-solid.svg';
import ImageGallery from '../../components/image-gallery/ImageGallery';
import Map from '../../components/map/Map';
import Parking from '../../components/parking/Parking';
import SaleRental from '../../components/sale-rental/SaleRental';
import './PropertyDetails.scss';
import PropertyDetailsSection from './PropertyDetailsSection';
import { PropertyDetailsViewModel } from './PropertyDetailsViewModel';



interface PropertyDetailsProps {
    refNumber: string
}

function PropertyDetails(props: PropertyDetailsProps) {

    const loc = useContext(LocalizationContext).localizer

    const appContext = useContext(AppDataContext);
    const [propertyDetailsViewModel, setPropertyDetailsViewModel] = useState<PropertyDetailsViewModel | null>(null)

    useEffect(() => {
        const listing = appContext.modelData.findListing(props.refNumber)
        if (listing != null) {
            setPropertyDetailsViewModel(new PropertyDetailsViewModel(listing, loc, appContext.modelData, appContext.search))
        }

    }, [appContext.modelData.properties.homes, setPropertyDetailsViewModel, loc])

    if (propertyDetailsViewModel === null) {
        return null
    }

    return (
        <div className='PropertyDetails'>
            <div className='PropertyDetailsHeader flex flex-wrap -mx-4 -mb-4 md:mb-0 mt-2'>
                <div className={`w-full ${propertyDetailsViewModel.showMap ? 'lg:w-2/3' : 'md:w-full'} mb-4 md:mb-0 rounded overflow-hidden bg-gray-100`}>
                    <ImageGallery images={propertyDetailsViewModel.images} shouldAutoscroll={true} showNavButtons={true} showPageButtons={true} fillContents={false} />
                </div>
                {propertyDetailsViewModel.showMap &&
                    <div className='w-full lg:w-1/3 px-4 mb-4 md:mb-0 hidden lg:block'>
                        <Map center={propertyDetailsViewModel.mapCoordinates!} markers={propertyDetailsViewModel.mapMarkerData} onMapMoved={() => { }} />
                    </div>
                }
            </div>

            <div className='PropertyDetailsContent flex flex-wrap -mx-4 -mb-4 md:mb-0 lg:mt-2'>
                <div className='w-full lg:w-2/3 px-4 mb-4 md:mb-0 mt-2'>

                    <h1 className='text-3xl md:text-5xl mb-2 mt-2 leading-tight font-semibold font-heading cap'>{propertyDetailsViewModel.propTitle}</h1>
                    <section className="pt-8 flex flex-wrap">
                        {propertyDetailsViewModel.propertySections.map((s, idx) => {
                            return <PropertyDetailsSection key={idx} {...s} />
                        })}
                    </section>

                    {propertyDetailsViewModel.season != null &&
                        <section className='mb-4'>
                            <SaleRental season={propertyDetailsViewModel.season} />
                        </section>
                    }

                </div>
                <div className='w-full lg:w-1/3 px-4 mb-4 md:mb-0 block lg:mt-3'>
                    <div className='pt-6 pr-6 pl-6 pb-2 bg-white rounded border-t-4 border-green-500 shadow'>
                        {propertyDetailsViewModel.neighborhoodDescription &&
                            <div className='mb-4 flex flex-row flex-nowrap items-center'>
                                <SVG className="Icon h-5 w-1/12 text-green-400 inline-block mr-4 flex-grow-0" src={MapIcon} />
                                <p className='w-11/12 inline-block text-md'>{propertyDetailsViewModel.neighborhoodDescription}</p>
                            </div>
                        }
                        {propertyDetailsViewModel.calmAreaDescription &&
                            <div className='mb-4 flex flex-row flex-nowrap items-center'>
                                <SVG className="Icon h-5 w-1/12 text-green-400 inline-block mr-4 flex-grow-0" src={FlowerIcon} />
                                <p className='w-11/12 inline-block text-md'>{propertyDetailsViewModel.calmAreaDescription}</p>
                            </div>
                        }
                        {propertyDetailsViewModel.centralAreaDescription &&
                            <div className='mb-4 flex flex-row flex-nowrap items-center'>
                                <SVG className="Icon h-5 w-1/12 text-green-400 inline-block mr-4 flex-grow-0" src={CityIcon} />
                                <p className='w-11/12 inline-block text-md'>{propertyDetailsViewModel.centralAreaDescription}</p>
                            </div>
                        }
                        {propertyDetailsViewModel.orientationDescription &&
                            <div className='mb-4 flex flex-row flex-nowrap items-center'>
                                <SVG className="Icon h-5 w-1/12 text-green-400 inline-block mr-4 flex-grow-0" src={CompassIcon} />
                                <p className='w-11/12 inline-block text-md'>{propertyDetailsViewModel.orientationDescription}</p>
                            </div>
                        }
                    </div>

                    {propertyDetailsViewModel.seaProximity.length > 0 &&
                        <div className='px-6 py-5 bg-white rounded border-t-4 border-blue-500 shadow mt-4'>
                            <div className='mb-2 flex flex-row flex-nowrap items-center' >
                                <SVG className="Icon h-5 w-1/12 text-blue-400 inline-block mr-4 flex-grow-0" src={WaterIcon} />
                                <div className='flex flex-col w-11/12 space-y-2'>
                                    {propertyDetailsViewModel.seaProximity.map((text, idx) => {
                                        return (
                                            <p className='inline-block cap text-md' key={idx}>{text}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }

                    {propertyDetailsViewModel.poiProximity.length > 0 &&
                        <div className='px-6 py-5 bg-white rounded border-t-4 border-red-500 shadow mt-4'>
                            <div className='mb-2 flex flex-row flex-nowrap items-top'>
                                <SVG className="Icon h-5 w-1/12 text-red-400 mr-4 mt-3 flex-grow-0" src={RulerIcon} />
                                <table className='w-11/12 inline-block flex-grow'><tbody>
                                    {propertyDetailsViewModel.poiProximity.map((p, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td className={`${idx !== 0 ? 'border-t' : ''} px-2 py-2 cap text-md`}>{p.title}</td>
                                                <td className={`${idx !== 0 ? 'border-t' : ''} px-2 py-2 cap text-md`}>~{Math.round(p.distance)} m</td>
                                            </tr>
                                        )
                                    })}
                                </tbody></table>
                            </div>
                            <p className='text-sm'>{loc.k('property.label.distance_disclaimer')}</p>

                        </div>

                    }


                    {propertyDetailsViewModel.parkings.map((p, idx) => {
                        return <Parking parking={p} key={idx} />
                    })}


                </div>

            </div>
        </div>
    );
}

export default PropertyDetails;
