import React, { useEffect } from 'react';

interface PageWTitleProps {
    title?: string
}

function PageWTitle(props: React.PropsWithChildren<PageWTitleProps>) {
    useEffect(()=>{
        document.title = props.title || ''
    }, [props.title])

    return <div>{props.children}</div>
}

export default PageWTitle;
