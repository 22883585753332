

export interface Localizer {

    /** Language code */
    lang: 'es' | 'en'

    /** Localizes an entire string, replacing all fields */
    s: (text: string) => string

    /** Localizes a single string */
    k: (text: string) => string
}

const localizations: { [key: string]: { 'en': string, 'es': string } } = {

    'home.steps.header': { en: 'Putting your home on the market is easy!', es: '¡Poner su vivienda en el mercado es fácil!' },

    'home.steps.sale-tab': { en: 'Sale', es: 'Venta' },

    'home.steps.rental-tab': { en: 'Rental', es: 'Alquiler' },

    'home.steps-sale.heading-1': { en: 'We photograph your home.', es: 'Fotografiamos su vivienda.' },
    'home.steps-sale.body-1': { en: 'We take pictures of your home. We then upload these to our site and other online portals.', es: 'Tomamos fotos de su propiedad, que luego cargamos en nuestra web y otros portales online. ' },
    'home.steps-sale.heading-2': { en: 'We manage the entire sale process.', es: 'Gestionamos la venta.' },
    'home.steps-sale.body-2': { en: 'We use your deeds, bills and other documentation to ensure that everything is in order. We resolve inheritances, update the property registry, cancel mortgages, review the average market value etc.', es: 'Con su escritura, recibos, y el resto de la documentación de la vivienda, nos aseguramos que esta todo en orden para la venta. Gestionamos herencias, inscripción al registro de la propiedad, cancelamos hipotecas, comprobamos el precio medio del mercado, etc.' },
    'home.steps-sale.heading-3': { en: 'That\'s it!', es: '¡Eso es todo!' },
    'home.steps-sale.body-3': { en: 'We put together a private contract, organize appointment at the notary, translate documentation if necessary, etc. to ensure a seamless experience on the day of the sale.', es: 'Confeccionamos el documento privado de compraventa, organizamos la cita con el notario, traducimos la documentación si el cliente no es dehabla española, etc. para un trámite sin problemas.' },

    'home.steps-rental.heading-1': { en: 'We photograph your home.', es: 'Fotografiamos su vivienda y calculamos los precios para la temporada.' },
    'home.steps-rental.body-1': { en: 'We take pictures of your home and find optimal prices based on monthly demand and results from previous seasons. Your home can then be rented out from the 1st of april to the 30th of october.', es: 'Tomamos fotos de su propiedad y encotramos precios óptimos basados en la demanda de cada mes y los resultados de temporadas anteriores. Alquilamos su vivienda entre el 1 de abril y el 30 de octubre.' },
    'home.steps-rental.heading-2': { en: 'We prepare your home for the rental.', es: 'Preparamos la vivienda para el alquiler.' },
    'home.steps-rental.body-2': { en: 'We clean out your home, remove private items, and ensure that guests have everything they\'ll need. When they come in, we collect a deposit and all the required paperwork.', es: 'Limpiamos la vivienda, retiramos objetos privados y nos aseguramos que los inquilinos tienen todos los enseres necesarios. A la entrada de los inquilinos cobramos fianzas y recogemos su documentación.' },
    'home.steps-rental.heading-3': { en: 'We look after your home.', es: 'Mantenemos su vivienda.' },
    'home.steps-rental.body-3': { en: 'At the end of the season, you get your home back clean and with fresh linnens, so you enjoy your home just like you left it.', es: 'Al término del alquiler le entregamos su apartamento limpio y con la ropa de cama lavada y planchada para que pueda usted disfrutar de su vivienda tal como la dejó.' },

    'home.hero.tagline0': { en: 'Your very own paradise retreat.', es: 'Su vivienda en el paraiso.' },
    'home.hero.tagline1': { en: 'One destination, two seas, infinite possibilites.', es: 'Un destino, dos mares, infinitas posibilidades.' },
    'home.hero.tagline2': { en: 'Love where you live.', es: 'Enamórese con La Manga.' },
    'home.hero.tagline3': { en: 'Sunrises over one sea, sunsets over another.', es: 'Amaneceres sobre un mar, atardeceres sobre otro.' },

    'home.about.about-la-manga-body-1': { en: 'La Manga del Mar Menor is a unique, 22 km-long strip of land that extends out into the Mediterranean and back in again forming a salt water lagoon known as the Mar Menor ("the smaller sea").', es: 'La Manga del Mar Menor es un cordón litoral de 22 kilómetros que se extiende desde Cabo de Palos hasta la punta de Él Mojón, formando una laguna natural de agua salada conocida como el Mar Menor.' },
    'home.about.about-la-manga-body-2': { en: 'Visitors can enjoy both the refreshing waves of the Mediterranean as well as the warm waters of Mar Menor at just a stone\'s throw from each other. There are few places in the world, if any, that allow you to see the sun rise over one sea and set over another.', es: 'Usted puede disfrutar tanto de las refrescantes olas del Mediterráneo como de las cálidas aguas del Mar Menor. Solo aquí podrá ver el sol salir y ponerse sobre dos mares distintos.' },

    'home.about.about-la-manga-header': { en: 'La Manga del Mar Menor', es: 'La Manga del Mar Menor' },

    'home.about.about-history-header': { en: '40 years at your service', es: 'A su servicio durane 40 años' },
    'home.about.about-history-body-1': { en: 'Inmobiliaria Euromar, open since 1982, is well known in La Manga for our services to both Spanish and international customers. With almost than 40 years of experience in real estate, we are the ideal choice if you want to purchase or sell a home or just rent a place for your holidays.', es: 'Inmobiliaria Euromar, abierta desde 1982, is bien conocida en La Manga por nuestros servicios tanto a clientes españoles como internacionales. Con casi 40 años de experiencia, somos, sin comparación,la mejor opcion a la hora de comprar, vender o alquilar inmuebles en La Manga del Mar Menor.' },
    'home.about.about-history-body-2': { en: 'Our office is open all week, year-round, in the heart of La Manga. You can find us at Plaza Bohemia right next the Gran Via. Don\'t hesitate to contact us by e-mail or phone to hear about our latest deals.', es: 'Nuestra oficina esta abierta toda la semana y todo el año junto a la Gran Vía a la altura de Plaza Bohemia. Si no tiene oportunidad de visitarnos, no dude en contactarnos por correo electrónico o llamarnos y le informaremos de nuestras últimas ofertas.' },

    
    'home.highlights.header': { en: 'Some of our highlights', es: 'Algunas de nuestras viviendas en venta' },
    
    
    'home.about.header': { en: 'About us and La Manga', es: 'Sobre Inmobiliaria Euromar y La Manga' },

    'label.capacity_adults_children': { en: 'capacity', es: 'capacidad' },
    'label.day': { en: 'day', es: 'día' },
    'label.month': { en: 'month', es: 'mes' },
    'label.rooms': { en: 'rooms', es: 'habitaciones' },
    'label.six_months': { en: 'semester', es: 'semestre' },
    'label.year': { en: 'year', es: 'año' },

    'navigation.buy': { en: 'Buy', es: 'Venta' },
    'navigation.home': { en: 'Home', es: 'Inicio' },
    'navigation.menu': { en: 'Menu', es: 'Menú' },
    'navigation.privacy': { en: 'Privacy Policy', es: 'Política de Privacidad' },
    'navigation.rent': { en: 'Rent', es: 'Alquiler' },
    'navigation.rental-conditions': { en: 'Rental Conditions', es: 'Condiciones de Alquiler' },
    'navigation.selling-interest': { en: 'Interested in Selling?', es: '¿Interesado en Vender?' },

    'prep.and': { en: 'and', es: 'y' },
    'prep.from': { en: 'from', es: 'desde' },
    'prep.in': { en: 'in', es: 'en' },
    'prep.of': { en: ' - ', es: 'de' },
    'prep.yes': { en: 'yes', es: 'si' },

    'property.label.accessibility.has_full_wheelchair_access': { en: 'Has full wheelchair access', es: 'Tiene acceso de silla de ruedas a todo el recinto' },
    'property.label.accessibility.has_lift': { en: 'Has lift', es: 'Tiene ascensor' },
    'property.label.accessibility.has_ramps': { en: 'Has ramps', es: 'Tiene rampas' },

    'property.label.area.is_calm': { en: 'This is a quiet area.', es: 'En zona tranquila.' },
    'property.label.area.is_central': { en: 'This is a central area.', es: 'En zona céntrica.' },

    'property.label.bathrooms_full': { en: 'bathrooms', es: 'baños' },
    'property.label.bathrooms_halfbath': { en: 'half-baths', es: 'aseos' },

    'property.label.commercial': { en: 'property', es: 'local' },

    'property.label.commercial_apt_for': { en: 'apt for ', es: 'apto para ' },

    'property.label.commercial_type.cafe': { en: 'café / bar', es: 'bar / cafetería' },
    'property.label.commercial_type.office': { en: 'office', es: 'oficina' },
    'property.label.commercial_type.open_space': { en: 'open-plan space', es: 'diáfano' },
    'property.label.commercial_type.restaurant': { en: 'restaurant', es: 'restaurante' },
    'property.label.commercial_type.retail': { en: 'retail', es: 'tienda' },
    'property.label.commercial_type.supermarket': { en: 'supermarket', es: 'supermercado' },

    'property.label.meters_indoor': { en: 'indoor space', es: 'interior' },
    'property.label.meters_terrace': { en: 'terrace', es: 'terraza' },
    'property.label.meters_storage': { en: 'storage', es: 'almacen' },
    'property.label.meters_refrigeration': { en: 'refrigerated room', es: 'cámara frigorífica' },

    'property.label.has_reception': { en: 'reception', es: 'recepción' },
    'property.label.num_office_rooms': { en: 'office rooms', es: 'oficinas' },


    'property.label.bathrooms_standard': { en: 'bathrooms', es: 'baños' },
    'property.label.bathrooms_accessible': { en: 'accessible bathrooms', es: 'aseos para discapacitados' },


    'property.label.commercial_permits': { en: 'Permits', es: 'Permisos' },
    'property.label.commercial_equipment': { en: 'Equipment', es: 'Equipmiento' },
    'property.label.commercial_furniture': { en: 'Furniture', es: 'Muebles' },

    'property.label.distance_disclaimer': { en: '* Line-of-sight distances. Actual walking distance may be greater.', es: '* Distancias en línea recta. La distancia actual andando puede ser mayor.' },

    'property.label.home_amenities.ample_terraces_plural': { en: 'large terraces', es: 'terrazas amplas' },
    'property.label.home_amenities.ample_terraces_singular': { en: 'large terrace', es: 'terraza amplia' },
    'property.label.home_amenities.barbequeue': { en: "barbequeue", es: 'barbacoa' },
    'property.label.home_amenities.deck_chairs': { en: "deck chairs", es: 'tumbonas' },
    'property.label.home_amenities.gardens_plural': { en: 'gardens', es: 'jardines' },
    'property.label.home_amenities.gardens_singular': { en: 'garden', es: 'jardin' },
    'property.label.home_amenities.kids_play_park': { en: "kid's playground", es: 'parque de juegos para niños' },
    'property.label.home_amenities.padel_court': { en: "padel court", es: 'pista de padel' },
    'property.label.home_amenities.patio': { en: "patio", es: 'patio' },
    'property.label.home_amenities.pools_plural': { en: 'pools', es: 'piscinas' },
    'property.label.home_amenities.pools_singular': { en: 'pool', es: 'piscina' },
    'property.label.home_amenities.porch': { en: "porch", es: 'porche' },
    'property.label.home_amenities.solariums_plural': { en: 'rooftop terraces', es: 'solarios' },
    'property.label.home_amenities.solariums_singular': { en: 'rooftop terrace', es: 'solario' },
    'property.label.home_amenities.storage': { en: "store room", es: 'trastero' },
    'property.label.home_amenities.tennis_court': { en: "tennis court", es: 'pista de tennis' },
    'property.label.home_amenities.terrace_awning': { en: "terrace awning", es: 'toldo de terraza' },
    'property.label.home_amenities.terrace_furniture': { en: "terrace furniture", es: 'muebles de terraza' },
    'property.label.home_amenities.terraces_plural': { en: 'terraces', es: 'terrazas' },
    'property.label.home_amenities.terraces_singular': { en: 'terrace', es: 'terraza' },
    'property.label.home_amenities.ventilation_fan': { en: 'ventilation fan', es: 'ventilador' },

    'property.label.other_amenities': { en: "other amenities", es: 'otras amenidades' },

    'property.label.home_bedrooms.studio': { en: 'studio', es: 'estudio' },
    'property.label.home_bedrooms.one_bedroom': { en: 'one bedroom', es: 'un dormitorio' },
    'property.label.home_bedrooms.two_bedrooms': { en: 'two bedrooms', es: 'dos dormitorios' },
    'property.label.home_bedrooms.three_bedrooms': { en: 'three bedrooms', es: 'tres dormitorios' },
    'property.label.home_bedrooms.four_bedrooms': { en: 'four bedrooms', es: 'cuatro dormitorios' },
    'property.label.home_bedrooms.five_bedrooms': { en: 'five bedrooms', es: 'cinco dormitorios' },
    'property.label.home_bedrooms.six_or_more_bedrooms': { en: 'six-bedrooms (or more)', es: 'seis dormitorios (o más)' },

    'property.label.home_floor': { en: 'floor', es: 'planta' },

    'property.label.home_floor.null': { en: '–', es: '–' },
    'property.label.home_floor.BasementSotano': { en: 'basement', es: 'sótano' },
    'property.label.home_floor.SemiBasementSemiSotano': { en: 'semi-basement', es: 'semi-sótano' },
    'property.label.home_floor.GroundFloorPlantaBaja': { en: 'ground floor', es: 'planta baja' },
    'property.label.home_floor.MezzanineEntresuelo': { en: 'mezzanine', es: 'entresuelo' },
    'property.label.home_floor.First': { en: '1st', es: '1ª' },
    'property.label.home_floor.Second': { en: '2nd', es: '2ª' },
    'property.label.home_floor.Third': { en: '3rd', es: '3ª' },
    'property.label.home_floor.Fourth': { en: '4th', es: '4ª' },
    'property.label.home_floor.Fifth': { en: '5th', es: '5ª' },
    'property.label.home_floor.Sixth': { en: '6th', es: '6ª' },
    'property.label.home_floor.Seventh': { en: '7th', es: '7ª' },
    'property.label.home_floor.Eighth': { en: '8th', es: '8ª' },
    'property.label.home_floor.Ninth': { en: '9th', es: '9ª' },
    'property.label.home_floor.Tenth': { en: '10th', es: '10ª' },
    'property.label.home_floor.Eleventh': { en: '11th', es: '11ª' },
    'property.label.home_floor.Twelfth': { en: '12th', es: '12ª' },
    'property.label.home_floor.Thirteenth': { en: '13th', es: '13ª' },
    'property.label.home_floor.Fourteenth': { en: '14th', es: '14ª' },
    'property.label.home_floor.Fifteenth': { en: '15th', es: '15ª' },
    'property.label.home_floor.Sixteenth': { en: '16th', es: '16ª' },
    'property.label.home_floor.Seventeenth': { en: '17th', es: '17ª' },
    'property.label.home_floor.Eighteenth': { en: '18th', es: '18ª' },
    'property.label.home_floor.Nineteenth': { en: '19th', es: '19ª' },
    'property.label.home_floor.Twentieth': { en: '20th', es: '20ª' },

    'property.label.home_type.apartment': { en: 'apartment', es: 'apartamento' },
    'property.label.home_type.attic': { en: 'attic', es: 'ático' },
    'property.label.home_type.bungalow': { en: 'bungalow', es: 'bungalow' },
    'property.label.home_type.duplex': { en: 'duplex', es: 'dúplex' },
    'property.label.home_type.villa': { en: 'villa', es: 'chalet' },

    'property.label.kitchen_dishwasher': { en: 'has dishwasher', es: 'lavajillas' },
    'property.label.kitchen_pantry': { en: 'has pantry', es: 'despensa' },
    'property.label.kitchen_washroom_is_glassed_in': { en: 'washroom is glassed in', es: 'lavadero acristalado' },
    'property.label.kitchen_washroom': { en: 'has washroom', es: 'lavadero' },
    'property.label.kitchens_american': { en: 'open-plan kitchens', es: 'cocinas americanas' },
    'property.label.kitchens_independent': { en: 'separate kitchens', es: 'cocinas independientes' },
    'property.label.kitchens_american_s': { en: 'open-plan kitchen', es: 'cocina americana' },
    'property.label.kitchens_independent_s': { en: 'separate kitchen', es: 'cocina independiente' },

    'property.label.meters_built': { en: 'constructed meters', es: 'metros construidos' },
    'property.label.meters_plot': { en: 'plot meters', es: 'metros parcela' },

    'property.label.month.jan': { en: 'jan.', es: 'ene.' },
    'property.label.month.feb': { en: 'feb.', es: 'feb.' },
    'property.label.month.mar': { en: 'mar.', es: 'mar.' },
    'property.label.month.apr': { en: 'apr.', es: 'abr.' },
    'property.label.month.may': { en: 'may', es: 'may.' },
    'property.label.month.jun': { en: 'jun.', es: 'jun.' },
    'property.label.month.jul': { en: 'jul.', es: 'jul.' },
    'property.label.month.aug': { en: 'aug.', es: 'ago.' },
    'property.label.month.sep': { en: 'sep.', es: 'sep.' },
    'property.label.month.oct': { en: 'oct.', es: 'oct.' },
    'property.label.month.nov': { en: 'nov.', es: 'nov.' },
    'property.label.month.dec': { en: 'dec.', es: 'dec.' },

    'property.label.neighborhood': { en: 'Located in the NB neighborhood.', es: 'En zona NB.' },

    'property.label.neighborhood.CaboDePalos': { en: 'Cabo de Palos', es: 'Cabo de Palos' },
    'property.label.neighborhood.CalaDelPino': { en: 'Cala del Pino', es: 'Cala del Pino' },
    'property.label.neighborhood.CastilloDeMar': { en: 'Castillo de Mar', es: 'Castillo de Mar' },
    'property.label.neighborhood.Cavanna': { en: 'Cavanna', es: 'Cavanna' },
    'property.label.neighborhood.Entremares': { en: 'Entremares', es: 'Entremares' },
    'property.label.neighborhood.Eurovosa': { en: 'Eurovosa', es: 'Eurovosa' },
    'property.label.neighborhood.HawaiiPedruchillo': { en: 'Hawaii / Pedruchillo', es: 'Hawaii / Pedruchillo' },
    'property.label.neighborhood.IbicencosVillaMorena': { en: 'Villa Morena', es: 'Villa Morena' },
    'property.label.neighborhood.LaGola': { en: 'La Gola', es: 'La Gola' },
    'property.label.neighborhood.MangaBeach': { en: 'Manga Beach', es: 'Manga Beach' },
    'property.label.neighborhood.PlayaHonda': { en: 'Playa Honda', es: 'Playa Honda' },
    'property.label.neighborhood.PlayaParaiso': { en: 'Playa Paraiso', es: 'Playa Paraiso' },
    'property.label.neighborhood.PlazaBohemia': { en: 'Plaza Bohemia', es: 'Plaza Bohemia' },
    'property.label.neighborhood.PuertoBello': { en: 'Puerto Bello', es: 'Puerto Bello' },
    'property.label.neighborhood.TomasMaestre': { en: 'Tomás Maestre', es: 'Tomás Maestre' },
    'property.label.neighborhood.Veneziola': { en: 'Veneziola', es: 'Veneziola' },
    'property.label.neighborhood.Zoco': { en: 'Zoco', es: 'Zoco' },

    'property.label.orientation_direction': { en: 'Oriented towards the OR.', es: 'Con orientación al OR.' },
    'property.label.orientation_East': { en: 'east', es: 'este' },
    'property.label.orientation_North': { en: 'north', es: 'norte' },
    'property.label.orientation_South': { en: 'south', es: 'sur' },
    'property.label.orientation_West': { en: 'west', es: 'oeste' },

    'property.label.parking_CommunitySurfaceParking_DeSuperficieComunitario': { en: 'Community surface parking', es: 'Parking comunitario' },
    'property.label.parking_PrivateGarage_CocheraDeSuperficie': { en: 'Garage', es: 'Garaje' },
    'property.label.parking_PrivateUndergroundGarage_CocheraSubterranea': { en: 'Underground garage', es: 'Cocherada numerada en sótano' },
    'property.label.parking_rental.conditional_availability': { en: 'Availability not guaranteed.', es: 'Disponibilidad no garantizada.' },
    'property.label.parking_rental.only_inclusive': { en: 'Only available with the property.', es: 'Solo disponible con la propriedad.' },
    'property.label.parking_rental.price': { en: 'Price / week:', es: 'Precio / semana:' },
    'property.label.parking_sale.included': { en: 'Included in sale price.', es: 'Incluido en el precio de venta.' },
    'property.label.parking_sale.only_inclusive': { en: 'Only available with the property.', es: 'Solo disponible con la propiedad.' },
    'property.label.parking_sale.price': { en: 'Price:', es: 'Precio:' },
    'property.label.parking_UndergroundNotNumbered_SubterraneoNoNumerado': { en: 'Underground parking', es: 'Parking en sótano' },
    'property.label.parking_UndergroundNumbered_SubterraneoNumerado': { en: 'Underground numbered parking', es: 'Parking numerado en sótano' },
    'property.label.parking_SurfaceParkingNumbered_DeSuperficieNumerado': { en: 'Numbered surface parking', es: 'Parking de superficie numerado' },

    'property.label.ref_no': { en: 'Reference no.', es: 'No. de referencia' },

    'property.label.rental.fortnightly_prices': { en: '* Prices may vary depending on which half of the month you\'re renting in.', es: '* Los precios varian dependiendo de que quincena del mes se alquila.' },
    'property.label.rental.price_14_nights': { en: '14 nights', es: '14 noches' },
    'property.label.rental.price_30_nights': { en: '30 nights', es: '30 noches' },
    'property.label.rental.price_7_nights': { en: '7 nights', es: '7 noches' },
    'property.label.rental.price_for_period': { en: '€ / period', es: '€ / periodo' },
    'property.label.rental.price_one_week': { en: 'one week *', es: 'semana *' },
    'property.label.rental.price_two_weeks': { en: 'two weeks *', es: 'quincenas *' },
    'property.label.rental.price': { en: 'Rental price:', es: 'Precio de alquiler:' },


    'property.label.sale.furniture_included': { en: 'furniture', es: 'muebles' },
    'property.label.sale.home_appliances_included': { en: 'home appliances', es: 'electrodomésticos' },
    'property.label.sale.home_goods_included': { en: 'household goods', es: 'enseres' },
    'property.label.sale.offers_mortgage_subrogation': { en: 'Mortgage subrogation', es: 'Subrogación de préstamo' },
    'property.label.sale.offers_payment_plans': { en: 'Payment plans', es: 'Otras formas de pago' },
    'property.label.sale.offers_payment_solutions': { en: 'The following payment solutions are also available:', es: 'Facilidades de pago disponibles:' },
    'property.label.sale.price_consult': { en: 'Please contact us to find out more.', es: 'A consultar.' },
    'property.label.sale.price_includes': { en: 'This price includes:', es: 'Este precio incluye:' },
    'property.label.sale.price': { en: 'Sale price:', es: 'Precio de venta:' },

    'property.label.sea_proximity.FirstLine': { en: 'waterfront views to the SEA.', es: 'en primera línea al SEA.' },
    'property.label.sea_proximity.Nearby': { en: 'near to the SEA.', es: 'proximo al SEA.' },
    'property.label.sea_proximity.PanoramicViews': { en: 'panoramic views to the SEA.', es: 'vistas panorámicas al SEA.' },
    'property.label.sea_proximity.Views': { en: 'views to the SEA.', es: 'vistas al SEA.' },

    'property.title.accessibility': { en: 'accessibility', es: 'accesibilidad' },
    'property.title.amenities': { en: 'amenities & extras', es: 'amenidades y extras' },
    'property.title.details': { en: 'details', es: 'detalles' },
    'property.title.overview': { en: 'overview', es: 'descripción' },
    'property.title.rental': { en: 'Rental', es: 'Alquiler' },
    'property.title.sale': { en: 'Sale', es: 'Venta' },

    'sea.mar_menor': { en: 'Mar Menor', es: 'Mar Menor' },
    'sea.mediterranean': { en: 'Mediterranean', es: 'Mediterráneo' },

    'search.searchFunction.all': { en: 'Sale & Rental', es: 'Venta y Alquiler' },
    'search.searchFunction.rental': { en: 'Rental', es: 'Alquiler' },
    'search.searchFunction.sale': { en: 'Sale', es: 'Venta' },

    'search.searchPropertySubtype.all': { en: 'All types', es: 'Todos los tipos' },
    'search.searchPropertySubtype.homeFourPlusBedroom': { en: 'Four-bedroom (or larger) Homes', es: 'Viviendas de 4+ Dormitorios' },
    'search.searchPropertySubtype.homeOneBedroom': { en: 'One-bedroom Homes', es: 'Viviendas de 1 Dormitorio' },
    'search.searchPropertySubtype.homeStudio': { en: 'Studio Apartments', es: 'Apartamentos Estudio' },
    'search.searchPropertySubtype.homeThreeBedroom': { en: 'Three-bedroom Homes', es: 'Viviendas de 3 Dormitorios' },
    'search.searchPropertySubtype.homeTwoBedroom': { en: 'Two-bedroom Homes', es: 'Viviendas de 2 Dormitorios' },

    'search.searchPropertyType.commercial': { en: 'Commercial Properties', es: 'Locales Comerciales' },
    'search.searchPropertyType.home': { en: 'Homes', es: 'Viviendas' },
    'search.searchPropertyType.parking': { en: 'Parking & Garages', es: 'Parking y Garajes' },
    'search.searchPropertyType.plot': { en: 'Plots', es: 'Parcelas' },

    'property.label.label_newly_added': { es: 'nuevo', en: 'new' },
    'property.label.label_on_sale': { es: 'rebajado', en: 'discounted' },
    'property.label.label_luxury': { es: 'de lujo', en: 'luxurious' },
    'property.label.label_very_cheap': { es: 'muy barato', en: 'budget-friendly' },
    'property.label.label_recently': { en: 'recently', es: 'recientemente' },
    'property.label.label_partially': { en: 'partially', es: 'parcialmente' },
    'property.label.label_fully': { en: 'fully', es: 'totalmente' },
    'property.label.label_renovated': { en: 'renovated', es: 'renovado' },


}

const replacementRegex = new RegExp('{[\\w\\-.]+}', 'g')

export function langLocalizer(lang: 'es' | 'en'): Localizer {
    return {
        lang: lang,
        s: (text: string) => {
            let t = localizeString(text, lang)
            return t
        },
        k: (text: string) => {
            return localizeKey(text, lang)
        }
    }
}

function localizeString(text: string, lang: 'es' | 'en'): string {
    return text.replaceAll(replacementRegex, key => { return localizeKey(key.substring(1, key.length - 1), lang) })
}


function localizeKey(key: string, lang: 'es' | 'en'): string {
    const localization = localizations[key]

    if (localization != null) {
        return localization[lang]
    }

    console.warn(`Localization missing for ${key}! `)
    return key
}