import React, { useContext, useState } from 'react';
import { LocalizationContext } from '../../../App';
import HomeStepsTab from './HomeStepsTab'
import HomeStepsList from './HomeStepsList'

enum ShownContent {
    Rental,
    Sale
}

function HomeSteps() {

    const loc = useContext(LocalizationContext).localizer
    const [content, setShownContent] = useState(ShownContent.Sale);

    const sellSteps = [
        { headingText: loc.k('home.steps-sale.heading-1'), bodyText: loc.k('home.steps-sale.body-1') },
        { headingText: loc.k('home.steps-sale.heading-2'), bodyText: loc.k('home.steps-sale.body-2') },
        { headingText: loc.k('home.steps-sale.heading-3'), bodyText: loc.k('home.steps-sale.body-3') },
    ]

    const rentalSteps = [
        { headingText: loc.k('home.steps-rental.heading-1'), bodyText: loc.k('home.steps-rental.body-1') },
        { headingText: loc.k('home.steps-rental.heading-2'), bodyText: loc.k('home.steps-rental.body-2') },
        { headingText: loc.k('home.steps-rental.heading-3'), bodyText: loc.k('home.steps-rental.body-3') },
    ]

    function toggleContent() {
        if (content === ShownContent.Sale) {
            setShownContent(ShownContent.Rental);
        } else {
            setShownContent(ShownContent.Sale);
        }
    }

    return (
        <section className='HomeSteps py-4 md:py-8 flex flex-col flex-nowrap items-center'>
            <h2 className='text-3xl mb-10 text-center font-semibold font-heading'>{loc.k('home.steps.header')}</h2>
            <div className='flex w-64 mb-12 mx-auto text-center border-b-2'>
                <HomeStepsTab title={loc.k('home.steps.sale-tab')} isSelected={content === ShownContent.Sale} onClick={toggleContent} />
                <HomeStepsTab title={loc.k('home.steps.rental-tab')} isSelected={content === ShownContent.Rental} onClick={toggleContent} />
            </div>

            {content === ShownContent.Sale &&
                <HomeStepsList steps={sellSteps} />
            }

            {content === ShownContent.Rental &&
                <HomeStepsList steps={rentalSteps} />
            }
        </section>
    );
}

export default HomeSteps;
