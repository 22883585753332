import React from 'react';

interface HomeStepsEntryProps {
    headingText: string
    bodyText: string
    stepCount: number
}

function HomeStepsEntry(props: HomeStepsEntryProps) {
    return (
        <div className={`md:w-1/${props.stepCount} px-4 mb-2 md:mb-4 lg:mb-0`}>
            <h3 className='text-2xl my-1 md:my-3 font-semibold font-heading text-left md:text-center'>{props.headingText}</h3>
            <p className='text-gray-400 leading-relaxed text-left md:text-center'>{props.bodyText}</p>
        </div>
    );
}

export default HomeStepsEntry