import React from 'react';
import { SaleAndRentalSeason } from '../../models/shared/SaleAndRentalSeason';
import { SaleRentalViewModel } from './SaleRentalViewModel';
import SaleRentalRentalSection from './SaleRentalRentalSection';
import SaleRentalSaleSection from './SaleRentalSaleSection';

interface SaleRentalProps {
    season: SaleAndRentalSeason
}

function SaleRental(props: SaleRentalProps) {
    const saleAndRentalViewModel = new SaleRentalViewModel(props.season)
    return (
        <div className='SaleRental'>
            { saleAndRentalViewModel.showSale &&
                <SaleRentalSaleSection viewModel={saleAndRentalViewModel} />
            }
            { saleAndRentalViewModel.showRental &&
                <SaleRentalRentalSection {...saleAndRentalViewModel.rentalTable()} />
            }
        </div>
    );
}

export default SaleRental;
