import React from 'react';

export interface HomeAboutSectionProps {
    title: string
    image?: string
    paragraphs: string[]
}

function HomeAboutSection(props: HomeAboutSectionProps) {
    return (
        <div className='w-full md:w-1/2 px-4 mb-8'>
            <div className='h-full p-6 bg-white rounded border-t-4 border-indigo-500 shadow'>
                <h3 className='text-2xl mb-4'>{props.title}</h3>
                { props.image && 
                    <img className='w-full rounded mb-4' src={props.image} />
                }
                {props.paragraphs.map((p, i) => {
                    return <p key={i} className='mb-4'>{p}</p>
                })}
            </div>
        </div>
    );
}

export default HomeAboutSection;
