import React from 'react';
import Icon, { IconProps } from '../../components/icon/Icon';

export interface PropertyDetailsSectionProps {
    icon: IconProps
    title: string
    attributes: PropertyAttribute[]
    paragraphs: string[]
}

export interface PropertyAttribute {
    title: string
    value: string
}

function PropertyDetailsSection(props: PropertyDetailsSectionProps) {
    return <div className='PropertyDetailsSection w-full md:w-1/2 px-3 mb-6'>

        <div className="flex flex-row flex-nowrap items-center mb-1 md:mb-3">
            <Icon {...props.icon} />
            <h3 className="text-xl ml-2 font-semibold font-heading cap inline-block">{props.title}</h3>
        </div>
        <table className="w-full table-auto">
            <tbody>
                {props.attributes.map((a, idx) => {
                    return (
                        <tr key={idx}>
                            <td className="border-t px-2 py-2 cap text-md">{a.title}</td>
                            <td className="border-t px-2 py-2 cap text-md">{a.value}</td>
                        </tr>
                    )
                })}

            </tbody>
        </table>
        {props.paragraphs.map((p, idx) => {
            return <p key={idx} className='text-md pt-2 m-1'>{p}</p>
        })}

    </div>;
}

export default PropertyDetailsSection;
