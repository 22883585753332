import React, { useContext, useEffect, useState } from 'react';
import { AppDataContext, LinkLocationContext } from '../../App';
import { AdminTableViewModel } from './AdminTableViewModel'

function AdminTable() {

    const modelData = useContext(AppDataContext).modelData
    const linkHost = useContext(LinkLocationContext).link
    const [tableViewModel, setTableViewModel] = useState<AdminTableViewModel | null>()
    
    useEffect(() => {
        setTableViewModel(new AdminTableViewModel(modelData, linkHost))
    }, [modelData, setTableViewModel])

    return (
        <div className='AdminTable'>
            { tableViewModel != null &&
                <table className='text-xs w-full'>
                    <thead>
                        <tr>
                            <th className='mr-2'>Ref.</th>
                            <th className='mr-2'>Type</th>
                            <th className='mr-2'>Name</th>
                            <th className='mr-2'>Attrs</th>
                            <th className='mr-2'>Home / Bedr.</th>
                            <th className='mr-2'>Season?</th>
                            <th className='mr-2'>Sale?</th>
                            <th className='mr-2'>Rent gen.?</th>
                            <th className='mr-2'>Rent east.?</th>
                            <th colSpan={2} className='mr-2'>Lat & Lon</th>
                            <th className='mr-2'>Images</th>
                            <th>DB ID</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tableViewModel.rows.map((r, idx) => {
                        return (
                            <tr key={idx} className='border-t hover:bg-gray-100'>
                                <td className='mr-2 py-2'>{r.refNo}</td>
                                <td className='mr-2 py-2'>{r.propType}</td>
                                <td className={`mr-2 py-2 ${r.propIsVisible ? '' : 'line-through text-gray-400'}`}><a className={'hover:underline'} href={r.viewLink}>{r.propName}</a></td>
                                <td className='mr-2 py-2'>{r.propAttrs}</td>
                                <td className='mr-2 py-2'>{r.homeBedrooms}</td>
                                <td className={`mr-2 py-2 ${r.propHasSeason ? '': 'bg-red-300'}`}>{r.propHasSeason ? 'Y' : 'N'}</td>
                                <td className='mr-2 py-2'>{r.propIsForSale ? 'Y' : 'N'}</td>
                                <td className='mr-2 py-2'>{r.propIsForRentalGeneral ? 'Y' : 'N'}</td>
                                <td className='mr-2 py-2'>{r.propIsForRentalEaster ? 'Y' : 'N'}</td>
                                <td className={`mr-2 py-2 ${r.propHasLat ? '': 'bg-red-300'}`}>{r.propHasLat ? 'Y' : 'N'}</td>
                                <td className={`mr-2 py-2 ${r.propHasLon ? '': 'bg-red-300'}`}>{r.propHasLon ? 'Y' : 'N'}</td>
                                <td className={`mr-2 py-2 ${r.propImageCount > 0 ? '': 'bg-red-300'}`}>{r.propImageCount}</td>
                                <td className='py-2'><a className={'hover:underline'} href={r.adminLink}>{r.id}</a></td>
                            </tr>
                        )
                    })}
                </tbody></table>
            }

        </div>
    );
}

export default AdminTable;
