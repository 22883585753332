import { SearchPropertyType } from './SearchPopertyType';

export enum SearchPropertySubtype {
    All = 'all',
    HomeStudio = 'homeStudio',
    HomeOneBedroom = 'homeOneBedroom',
    HomeTwoBedroom = 'homeTwoBedroom',
    HomeThreeBedroom = 'homeThreeBedroom',
    HomeFourPlusBedroom = 'homeFourPlusBedroom'
}

export const AllSearchPropertySubtypes: SearchPropertySubtype[] = [SearchPropertySubtype.All];

export function searchPropertySubtypesForSearchPropertyType(func: SearchPropertyType): SearchPropertySubtype[] {
    if (func === SearchPropertyType.Home) {
        // .Home
        return [
            SearchPropertySubtype.All,
            SearchPropertySubtype.HomeStudio,
            SearchPropertySubtype.HomeOneBedroom,
            SearchPropertySubtype.HomeTwoBedroom,
            SearchPropertySubtype.HomeThreeBedroom,
            SearchPropertySubtype.HomeFourPlusBedroom
        ];

    } else if (func === SearchPropertyType.Commercial) {
        // .Commercial
        return [SearchPropertySubtype.All];

    } else if (func === SearchPropertyType.Parking) {
        // .Parking
        return [SearchPropertySubtype.All];
    } else {

        // .Plot
        return [SearchPropertySubtype.All];
    }
}
