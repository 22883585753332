import React, { useContext, useEffect, useState } from 'react';
import { AppDataContext, LocalizationContext } from '../../../App';
import { PropertyListing } from '../../../models/list-data/PropertyListing';
import { SearchFunction } from '../../../models/search/SearchFunction';
import { SearchPropertyType } from '../../../models/search/SearchPopertyType';
import { SearchPropertySubtype } from '../../../models/search/SearchPropertySubtype';
import PropertyEntry from '../../property-list/property-entry/PropertyEntry';

function HomeHighlights() {
    const appContext = useContext(AppDataContext)
    const loc = useContext(LocalizationContext).localizer

    const [listings, setListings] = useState<PropertyListing[]>([])

    useEffect(() => {
        let season = appContext.modelData.findSeason()

        if (season == null) {
            return
        }

        setListings(appContext.modelData.allListings()
            .filter(l => {
                return l.matchesSearch(SearchFunction.Sale, SearchPropertyType.Home, SearchPropertySubtype.All, season!)
            })
            .sort(() => {
                return 0.5 - Math.random()
            }).slice(0, 4))

    }, [appContext.modelData, setListings])

    if (listings.length > 0) {
        return (
            <section className='HomeHighlights py-4 md:py-8 hidden sm:block'>
                <h2 className='text-3xl text-center mb-8 font-semibold font-heading'>{loc.k('home.highlights.header')}</h2>
                { listings.length > 3 &&
                    <div className='flex flex-row flex-nowrap justify-evenly items-stretch'>
                        <div className='flex flex-row items-stretch sm:w-1/2 md:w-1/3 lg:w-1/4'> <PropertyEntry maxElemsInRow={1} listing={listings[0]} /></div>
                        <div className='flex flex-row items-stretch sm:w-1/2 md:w-1/3 lg:w-1/4'> <PropertyEntry maxElemsInRow={1} listing={listings[1]} /></div>
                        <div className='flex-row items-stretch hidden md:flex md:w-1/3 lg:w-1/4'> <PropertyEntry maxElemsInRow={1} listing={listings[2]} /></div>
                        <div className='flex-row items-stretch hidden lg:flex lg:w-1/4'> <PropertyEntry maxElemsInRow={1} listing={listings[3]} /></div>
                    </div>
                }
            </section>
        );
    } else {
        return null
    }
}

export default HomeHighlights;
