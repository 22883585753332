import React from 'react';
import HomeStepsEntry from './HomeStepsEntry';

interface HomeStepsListProps {
    steps: { headingText: string, bodyText: string }[]
}

function HomeStepsList(props: HomeStepsListProps) {
    return (
        <div className='flex flex-wrap max-w-6xl sm:mx-2 text-center'>
            { props.steps.map((step, idx) => {
                return <HomeStepsEntry {...step} stepCount={props.steps.length} key={idx} />
            })}
        </div>
    );
}

export default HomeStepsList