import React from 'react';

interface HomeStepsTabProps {
    title: string;
    isSelected: boolean;
    onClick: () => void;
}

function HomeStepsTab(props: HomeStepsTabProps) {
    return (
        <div className={'w-1/2 pb-2' + (props.isSelected ? ' border-b-4 border-indigo-600' : '')}>
            <button className='hover:text-indigo-600' onClick={props.onClick}>
                {props.title}
            </button>
        </div>
    );
}

export default HomeStepsTab