
import React, { useContext } from 'react';
import { LocalizationContext } from '../../App';
import { Parking as ParkingModel } from '../../models/properties/Parking';
import { ParkingViewModel } from './ParkingViewModel';
import SVG from 'react-inlinesvg';
import CarIcon from '../../assets/svg/property/car-side-solid.svg';

interface ParkingProps {
    parking: ParkingModel
}

function Parking(props: ParkingProps) {
    const loc = useContext(LocalizationContext).localizer
    const parkingViewModel = new ParkingViewModel(props.parking, loc)
    return (
        <div className='Parking px-6 py-5 bg-white rounded border-t-4 border-gray-500 shadow mt-4'>
            <div className='mb-3 flex flex-row flex-nowrap items-center'>
                <SVG className="Icon h-5 w-1/12 text-gray-400 inline-block mr-4 flex-grow-0" src={CarIcon} />
                <p className='w-11/12 inline-block text-md'>{parkingViewModel.title}</p>
            </div>
            { parkingViewModel.showSale &&
                <div className='border-t pt-2 mb-2'>
                    {parkingViewModel.salePriceText !== '' &&
                        <p className='text-md mt-1'><span className='mr-1'>{parkingViewModel.saleTitleText}</span><span>{parkingViewModel.salePriceText}</span></p>
                    }
                    {parkingViewModel.saleAdditionalTexts.length > 0 &&
                        <ul className='list-inside list-disc mt-2 pl-2'>
                            {parkingViewModel.saleAdditionalTexts.map((t, idx) => {
                                return <li className='text-md mb-1' key={idx}>{t}</li>
                            })}
                        </ul>
                    }
                </div>
            }
            { parkingViewModel.showRental &&
                <div className='border-t pt-2'>
                    {parkingViewModel.rentalPriceText !== '' &&
                        <p className='text-md mt-1'><span className=' mr-1'>{parkingViewModel.rentalTitleText}</span><span>{parkingViewModel.rentalPriceText}</span></p>
                    }
                    {parkingViewModel.rentalAdditionalTexts.length > 0 &&
                        <ul className='list-inside list-disc mt-2 pl-2'>
                            {parkingViewModel.rentalAdditionalTexts.map((t, idx) => {
                                return <li className='text-m mb-1 ' key={idx}>{t}</li>
                            })}
                        </ul>
                    }
                </div>
            }
        </div>
    );
}

export default Parking;
