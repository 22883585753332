import React, { useContext } from 'react';
import { LocalizationContext } from '../../App';

function Footer() {

    const loc = useContext(LocalizationContext).localizer

    return (
        <footer className='Footer flex flex-wrap items-center justify-between p-4'>
            <div className='w-full lg:w-auto lg:mr-6 mb-4 lg:mb-0 text-center'>
                <div className='flex flex-col items-center lg:items-start'>
                    <div>E-mail: <a className='text-indigo-800 hover:underline' href='mailto:ieuromar@gmail.com'>ieuromar@gmail.com</a> Tel: <a className='text-indigo-800 hover:underline' href='tel:+34 609 72 13 94'>+34 609 72 13 94</a></div>
                    <div>&copy; 2021 Inmobiliaria Euromar</div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row items-center w-full lg:w-auto'>
                <div className='mx-auto lg:mx-0 lg:ml-auto'>
                    <a href='#' className='inline-block mt-0 text-blue-900 hover:text-indigo-600'>
                        {loc.k('navigation.home')}
                    </a>
                    <a href='#' className='inline-block mt-0  ml-8 text-blue-900 hover:text-indigo-600'>
                        {loc.k('navigation.privacy')}
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
