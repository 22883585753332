import React, { useContext } from 'react';
import { LocalizationContext } from '../../App';
import Icon from '../icon/Icon';
import { IconColor, IconIntensity, IconSize } from '../icon/IconStyle';
import { IconType } from '../icon/IconType';

export interface RentalRow {
    header: string
    isSplit: boolean
    values: string[]
}

export interface SaleRentalRentalSectionProps {
    headerRow: RentalRow
    bodyRows: RentalRow[]
    rentalYear: string | null
    rentalSixMonths: string | null
}

function SaleRentalRentalSection(props: SaleRentalRentalSectionProps) {
    const loc = useContext(LocalizationContext).localizer

    return (
        <div className='RentalSection px-4'>
            <div className="flex flex-row flex-nowrap items-center mb-1 md:mb-3">
                <Icon type={IconType.calendar} style={{ color: IconColor.indigo, intensity: IconIntensity.i600, size: IconSize.l }} />
                <h3 className="text-xl ml-2 font-semibold font-heading cap inline-block">{loc.k('property.title.rental')}</h3>
            </div>
            { (props.rentalYear || props.rentalSixMonths) &&
                <div className='w-full border-t mb-4 pt-2'>
                    <span className='mr-2'>{loc.k('property.label.rental.price')}</span>
                    {props.rentalYear &&
                        <span className='font-semibold'>{props.rentalYear}/{loc.k('label.year')}</span>
                    }
                    {props.rentalSixMonths &&
                        <span className='font-semibold'>{props.rentalSixMonths} / {loc.k('label.six_months')}</span>
                    }
                </div>
            }


            { props.bodyRows.length > 0 &&

                <div>
                    <table className='w-full'>
                        <thead>
                            <tr>
                                <th className='sm:px-2 py-2 text-left cap border-t text-xs md:text-base'>{loc.s(props.headerRow.header)}</th>
                                {props.headerRow.values.map((v, idx) => {
                                    return <th className='sm:px-2 py-2 cap border-t text-xs md:text-base' colSpan={props.headerRow.isSplit ? 1 : 2} key={idx}>{loc.s(v)}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {props.bodyRows.map((br, idx) => {
                                return (
                                    <tr key={idx}>
                                        <th className={`sm:px-2 py-2 text-left border-t cap text-xs md:text-base`}>{loc.s(br.header)}</th>
                                        {br.values.map((v, idx) => {
                                            return <td className={`sm:px-2 py-2 border-t text-center cap text-xs md:text-base ${br.isSplit ? (idx % 2 === 0 ? 'border-l' : '') : 'border-l'}`} colSpan={br.isSplit ? 1 : 2} key={idx}>{loc.s(v)}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <p className='text-sm mt-4'>{loc.k('property.label.rental.fortnightly_prices')}</p>
                </div>
            }
        </div >
    );
}

export default SaleRentalRentalSection;
