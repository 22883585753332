
export class ModelListFetcher {

    private _host: string
    private _basePath: string

    constructor(host: string, basePath: string) {
        this._host = host
        this._basePath = basePath
    }

    fetch<T>(path: string): Promise<T[] | null> {
        return new Promise<T[] | null>((resolve, reject) => {
            fetch(`${this._host}${this._basePath}${path}`)
                .then(res => res.json())
                .then(data => {
                    resolve(data as unknown as T[])
                })
                .catch((err) => {
                    console.log(err)
                    resolve(null)
                })
        })

    }
}