import React from 'react';

interface GenericButtonProps {
    text?: string
    href?: string
    onClick?: () => void
}

function GenericButton(props: GenericButtonProps) {
    return (
        <button
            className="inline-block py-3 px-6 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow"
            onClick={() => {
                if (props.onClick != null) {
                    props.onClick()
                }
            }}>{props.text != null ? props.text : ""}</button>
    );
}

export default GenericButton;
