import { MapMarkerType } from '../../components/map/MapMarkerType'
import { Localizer } from '../../services/Localizer'
import { Commercial, localizedEquipment, localizedFurniture, localizedPermits } from '../properties/Commercial'
import { Home, localizedHomeAmenities, HomeBedrooms } from '../properties/Home'
import { Parking } from '../properties/Parking'
import { Plot } from '../properties/Plot'
import { SearchFunction } from '../search/SearchFunction'
import { SearchPropertyType } from '../search/SearchPopertyType'
import { SearchPropertySubtype } from '../search/SearchPropertySubtype'
import { MapCoordinates } from '../shared/MapCoordinates'
import { SaleAndRentalSeason } from '../shared/SaleAndRentalSeason'
import { Season } from '../shared/Season'

const NoRentalValue = 1000000

export class PropertyListing {

    commercial: Commercial | null = null
    home: Home | null = null
    parking: Parking | null = null
    plot: Plot | null = null
    propertyType: SearchPropertyType | null

    constructor(
        commercial: Commercial | null,
        home: Home | null,
        parking: Parking | null,
        plot: Plot | null) {

        this.commercial = commercial
        this.home = home
        this.parking = parking
        this.plot = plot

        if (this.commercial != null) {
            this.propertyType = SearchPropertyType.Commercial
        } else if (this.home != null) {
            this.propertyType = SearchPropertyType.Home
        } else if (this.parking != null) {
            this.propertyType = SearchPropertyType.Parking
        } else if (this.plot != null) {
            this.propertyType = SearchPropertyType.Plot
        } else {
            this.propertyType = null
        }
    }

    static FromCommercial(commercial: Commercial): PropertyListing {
        return new PropertyListing(commercial, null, null, null)
    }

    static FromHome(home: Home): PropertyListing {
        return new PropertyListing(null, home, null, null)
    }

    computedTitle(loc: Localizer): string {
        let title = ''

        if (this.propertyType === SearchPropertyType.Home) {
            let home = this.home!

            if (home.home_general.is_apartment) {
                title += loc.k("property.label.home_type.apartment")
            } else if (home.home_general.is_attic) {
                title += loc.k("property.label.home_type.attic")
            } else if (home.home_general.is_bungalow) {
                title += loc.k("property.label.home_type.bungalow")
            } else if (home.home_general.is_villa) {
                title += loc.k("property.label.home_type.villa")
            } else if (home.home_general.is_duplex) {
                title += loc.k("property.label.home_type.duplex")
            } else {
                title += loc.k("property.label.home_type.generic")
            }

            title += ` ${loc.k("prep.in")}`
            title += ` ${home.property.public_name}`

        } else if (this.propertyType === SearchPropertyType.Commercial) {
            let comm = this.commercial!

            title += loc.k('property.label.commercial')
            title += ` ${loc.k("prep.in")}`
            title += ` ${comm.property.public_name}`
        }

        return title
    }

    computedSummary(loc: Localizer): string {
        if (this.propertyType === SearchPropertyType.Home) {
            let home = this.home!
            return loc.s(localizedHomeAmenities(home))

        } else if (this.propertyType === SearchPropertyType.Commercial) {
            let comm = this.commercial!

            let text = ''

            if (comm.commercial_general.equipment_en || comm.commercial_general.equipment_es) {
                text += `${loc.k('property.label.commercial_equipment')}: ${localizedEquipment(comm, loc)}. `
            }

            if (comm.commercial_general.furniture_en || comm.commercial_general.furniture_es) {
                text += `${loc.k('property.label.commercial_furniture')}: ${localizedFurniture(comm, loc)}. `
            }

            if (comm.commercial_general.permits_en || comm.commercial_general.permits_es) {
                text += `${loc.k('property.label.commercial_permits')}: ${localizedPermits(comm, loc)}.`
            }

            return text
        }

        return ''
    }

    computedLabels(loc: Localizer): string[] {
        let labels: string[] = []

        if (this.propertyType === SearchPropertyType.Home) {
            let home = this.home!

            if (home.labels.newly_added) {
                labels.push(loc.k('property.label.label_newly_added'))
            }

            if (home.labels.on_sale) {
                labels.push(loc.k('property.label.label_on_sale'))
            }

            if (home.labels.luxury) {
                labels.push(loc.k('property.label.label_luxury'))
            }

            if (home.labels.very_cheap) {
                labels.push(loc.k('property.label.label_very_cheap'))
            }

            if (home.labels.recently_renovated || home.labels.partially_renovated || home.labels.fully_renovated) {
                let renovatedLabels = []

                if (home.labels.recently_renovated) {
                    renovatedLabels.push(loc.k('property.label.label_recently'))
                }

                if (home.labels.partially_renovated) {
                    renovatedLabels.push(loc.k('property.label.label_partially'))
                }

                if (home.labels.fully_renovated) {
                    renovatedLabels.push(loc.k('property.label.label_fully'))
                }
    
                labels.push(loc.s(`${renovatedLabels.join(', ')} {property.label.label_renovated}`))

            }
        }

        return labels
    }

    isForRental(season: Season): boolean {
        if (this.propertyType === SearchPropertyType.Home || this.propertyType === SearchPropertyType.Commercial) {
            const seasonPrices = this.currentSaleAndRentalSeason(season)

            if (seasonPrices != null) {
                return seasonPrices.is_for_rental_general
            }
        }

        return false
    }

    isForSale(season: Season): boolean {
        if (this.propertyType === SearchPropertyType.Home || this.propertyType === SearchPropertyType.Commercial) {
            const seasonPrices = this.currentSaleAndRentalSeason(season)

            if (seasonPrices != null) {
                return seasonPrices.is_for_sale
            }
        }

        return false
    }

    get refNo(): string {
        if (this.propertyType === SearchPropertyType.Home) {
            let home = this.home!
            return home.property.ref_number
        } else if (this.propertyType === SearchPropertyType.Commercial) {
            let comm = this.commercial!
            return comm.property.ref_number
        }
        return ''
    }

    get mapMarkerType(): MapMarkerType | null {
        if (this.propertyType === SearchPropertyType.Home) {
            return MapMarkerType.home
        } else if (this.propertyType === SearchPropertyType.Commercial) {
            return MapMarkerType.commercial
        }

        return null
    }

    get mapCoordinates(): MapCoordinates | null {
        if (this.home != null) {
            if (this.home.location && this.home.location.latitude && this.home.location.longitude) {
                return this.home.location
            }
        } else if (this.commercial != null) {
            if (this.commercial.location && this.commercial.location.latitude && this.commercial.location.longitude) {
                return this.commercial.location
            }
        }

        return null
    }

    matchesSearch(searchFunction: SearchFunction, searchType: SearchPropertyType, searchSubtype: SearchPropertySubtype, season: Season): boolean {
        let saleAndRentalSeason = this.currentSaleAndRentalSeason(season)

        if (saleAndRentalSeason == null) {
            return false
        }

        let matchesFunction = false

        if (searchFunction === SearchFunction.All) {
            matchesFunction = saleAndRentalSeason.is_for_rental_general || saleAndRentalSeason.is_for_rental_easter || saleAndRentalSeason.is_for_sale
        } else if (searchFunction === SearchFunction.Sale) {
            matchesFunction = saleAndRentalSeason.is_for_sale
        } else if (searchFunction === SearchFunction.Rental) {
            matchesFunction = saleAndRentalSeason.is_for_rental_easter || saleAndRentalSeason.is_for_rental_general
        }

        if (!matchesFunction) {
            return false
        }

        let matchesType = searchType === this.propertyType

        if (!matchesType) {
            return false
        }

        let matchesSubtype = false

        if (searchSubtype === SearchPropertySubtype.All) {
            matchesSubtype = true

        } else if (searchType === SearchPropertyType.Home) {
            let home = this.home!
            if (searchSubtype === SearchPropertySubtype.HomeStudio) {
                matchesSubtype = (home.home_general.bedrooms === HomeBedrooms.Studio)

            } else if (searchSubtype === SearchPropertySubtype.HomeOneBedroom) {
                matchesSubtype = (home.home_general.bedrooms === HomeBedrooms.OneBedroom)

            } else if (searchSubtype === SearchPropertySubtype.HomeTwoBedroom) {
                matchesSubtype = (home.home_general.bedrooms === HomeBedrooms.TwoBedrooms)

            } else if (searchSubtype === SearchPropertySubtype.HomeThreeBedroom) {
                matchesSubtype = (home.home_general.bedrooms === HomeBedrooms.ThreeBedrooms)

            } else if (searchSubtype === SearchPropertySubtype.HomeFourPlusBedroom) {
                matchesSubtype = (home.home_general.bedrooms === HomeBedrooms.FourBedrooms ||
                    home.home_general.bedrooms === HomeBedrooms.FiveBedrooms ||
                    home.home_general.bedrooms === HomeBedrooms.SixOrMoreBedrooms)
            }
        }

        return matchesSubtype
    }

    currentSaleAndRentalSeason(season: Season): SaleAndRentalSeason | null {
        if (this.propertyType === SearchPropertyType.Home) {
            const home = this.home!

            return home.sale_and_rental_seasons.filter(s => {
                return s.season === season.id
            })[0] ?? null
        } else if (this.propertyType === SearchPropertyType.Commercial) {
            const comm = this.commercial!

            return comm.sale_and_rental_seasons.filter(s => {
                return s.season === season.id
            })[0] ?? null
        }

        return null
    }

    previewPriceLocalized(func: SearchFunction, season: Season | null): string {
        if (season === null) {
            return ''
        }

        if (this.propertyType === SearchPropertyType.Home) {
            const seasonPrices = this.currentSaleAndRentalSeason(season)

            if (seasonPrices === null) {
                return ''
            }

            // Sale prices
            const hasSalePrice = seasonPrices.sale_price_original != null || seasonPrices.sale_price_discounted != null

            // Rental prices
            const lowestDailyPrice = Math.min(...[
                seasonPrices.rental_jan, seasonPrices.rental_feb, seasonPrices.rental_mar,
                seasonPrices.rental_apr, seasonPrices.rental_may, seasonPrices.rental_jun,
                seasonPrices.rental_jul, seasonPrices.rental_aug, seasonPrices.rental_sep,
                seasonPrices.rental_oct, seasonPrices.rental_nov, seasonPrices.rental_dec].map(month => {
                    return Math.min(...([
                        month.daily_30_days,
                        month.daily_two_weeks_1st_quarter, month.daily_two_weeks_2nd_quarter,
                        month.daily_one_week_1st_quarter, month.dailiy_one_week_2nd_quarter,
                        NoRentalValue
                    ].filter(n => {
                        return typeof n !== 'undefined'
                    }) as number[]))
                }))

            if ((func === SearchFunction.All || func === SearchFunction.Sale) && this.isForSale(season) && hasSalePrice) {
                const price = seasonPrices.sale_price_discounted ?? seasonPrices.sale_price_original

                if (price != null) {
                    return `${Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(price)}`
                }

            } else if ((func === SearchFunction.All || func === SearchFunction.Rental) && this.isForRental(season)) {
                if (lowestDailyPrice !== NoRentalValue) {
                    return `{prep.from} ${Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(lowestDailyPrice)}/{label.day}`
                }
            }
        } else if (this.propertyType === SearchPropertyType.Commercial) {
            const seasonPrices = this.currentSaleAndRentalSeason(season)

            if (seasonPrices === null) {
                return ''
            }

            // Sale prices
            const hasSalePrice = seasonPrices.sale_price_original != null || seasonPrices.sale_price_discounted != null

            // Rental prices
            let monthlyPrice: number | null = null

            if (seasonPrices.rental_price_year != null && seasonPrices.rental_price_six_months != null) {
                if ((seasonPrices.rental_price_year / 12) < (seasonPrices.rental_price_six_months / 6)) {
                    monthlyPrice = seasonPrices.rental_price_year / 12
                } else {
                    monthlyPrice = seasonPrices.rental_price_six_months / 6
                }

            } else if (seasonPrices.rental_price_year != null) {
                monthlyPrice = seasonPrices.rental_price_year / 12

            } else if (seasonPrices.rental_price_six_months != null) {
                monthlyPrice = seasonPrices.rental_price_six_months / 6

            }

            if ((func === SearchFunction.All || func === SearchFunction.Sale) && this.isForSale(season) && hasSalePrice) {
                const price = seasonPrices.sale_price_discounted ?? seasonPrices.sale_price_original

                if (price != null) {
                    return `${Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(price)}`
                }

            } else if ((func === SearchFunction.All || func === SearchFunction.Rental) && this.isForRental(season)) {
                if (monthlyPrice !== null) {
                    return `{prep.from} ${Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(monthlyPrice)}/{label.month}`
                }
            }

        }

        return ''

    }

}