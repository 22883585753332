import React, { useContext, useEffect, useRef, useState } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { LinkLocationContext } from '../../App';
import HomePlaceholder from '../../assets/images/home-placeholder.png';
import { MediaPhoto } from '../../models/shared/Media';
import './ImageGallery.scss';

SwiperCore.use([Navigation, Pagination, Autoplay])

interface ImageGalleryProps {
    images: MediaPhoto[],
    shouldAutoscroll: boolean
    showPageButtons: boolean
    showNavButtons: boolean
    fillContents: boolean
}

function ImageGallery(props: ImageGalleryProps) {
    const [width, setWidth] = useState(0)

    const swiperRef = useRef<SwiperCore | null>(null)

    function updateWidth(w: number) {
        if (w !== width) {
            setWidth(w)
        }
    }

    useEffect(() => {
        if (swiperRef.current != null) {
            if (props.shouldAutoscroll) {
                swiperRef.current.autoplay.start()
            } else {
                swiperRef.current.autoplay.stop()
            }
        }
    }, [props.shouldAutoscroll, swiperRef.current])

    const location = useContext(LinkLocationContext)

    return (
        <Swiper
            onSwiper={s => { swiperRef.current = s }}
            loop={props.images.length > 0}
            centerInsufficientSlides={true}
            onInit={s => { updateWidth(s.width) }}
            onResize={s => { updateWidth(s.width) }}
            resizeObserver
            navigation={props.images.length > 0 && props.showNavButtons}
            speed={400}
            pagination={props.images.length > 0 && props.showPageButtons ? { clickable: true } : false}
            autoplay={props.shouldAutoscroll ? { delay: 4000 } : false}
            className='w-full h-full rounded'
            style={{ maxHeight: width * 0.75 + 'px', height: width * 0.75 + 'px' }}>
            {props.images.length > 0 &&
                props.images.map((image, idx) => {

                    return (
                        <SwiperSlide key={idx}>
                            <img src={`${location.link}${image.formats.large?.url ?? image.url}`} style={{ objectFit: props.fillContents ? 'cover' : 'contain' }} />
                        </SwiperSlide>
                    )
                })
            }
            {props.images.length === 0 &&
                <SwiperSlide style={{ maxWidth: '100%' }}>
                    <img src={HomePlaceholder} className='w-full h-full' style={{ objectFit: 'cover', maxWidth: '100%' }} />
                </SwiperSlide>
            }

        </Swiper>
    );
}

export default ImageGallery;
