import React, { useContext } from 'react';
import { LocalizationContext } from '../../App';
import Icon from '../icon/Icon';
import { IconColor, IconIntensity, IconSize } from '../icon/IconStyle';
import { IconType } from '../icon/IconType';
import { SaleRentalViewModel } from './SaleRentalViewModel';

interface SaleRentalSaleSectionProps {
    viewModel: SaleRentalViewModel
}

function SaleRentalSaleSection(props: SaleRentalSaleSectionProps) {
    const loc = useContext(LocalizationContext).localizer

    return (
        <div className='RentalSection px-4 mb-4'>
            <div className="flex flex-row flex-nowrap items-center pb-2 mb-1 md:mb-3 border-b">
                <Icon type={IconType.handshake} style={{ color: IconColor.indigo, intensity: IconIntensity.i600, size: IconSize.l }} />
                <h3 className="text-xl ml-2 font-semibold font-heading cap inline-block">{loc.k('property.title.sale')}</h3>
            </div>
        
            <div className="flex flex-wrap">
                <div className="w-full md:w-2/3 mb-4">
                    <span className='mr-2'>{loc.k('property.label.sale.price')}</span>
                    <span className={`font-semibold ${props.viewModel.salePriceDiscounted ? 'text-gray-400 line-through' : ''}`}>{props.viewModel.salePriceOriginal ? Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(props.viewModel.salePriceOriginal) : loc.k('property.label.sale.price_consult')}</span>
                    {props.viewModel.salePriceDiscounted && 
                        <span className='font-semibold ml-2'>{Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(props.viewModel.salePriceDiscounted)}</span>
                    }
                    {props.viewModel.saleAnnotations.length > 0 &&
                        <div>
                            <p className='mt-4 mb-2'>{loc.k('property.label.sale.offers_payment_solutions')}</p>
                            <ul className='list-inside list-disc px-2'>
                                { props.viewModel.saleAnnotations.map((sa, idx) => {
                                    return <li className=' mt-1' key={idx}>{loc.k(sa)}</li>
                                })}
                            </ul>
                        </div>
                    }
                </div>
                <div className="w-full md:w-1/3">
                    {props.viewModel.saleIncludedItems.length > 0 &&
                        <div>
                            <p className='mb-2'>{loc.k('property.label.sale.price_includes')}</p>
                            <ul className='list-inside list-disc px-2'>
                                { props.viewModel.saleIncludedItems.map((sa, idx) => {
                                    return <li className=' mt-1 cap' key={idx}>{loc.k(sa)}</li>
                                })}
                            </ul>
                        </div>
                    }
                </div>
            </div>

        </div >
    );
}

export default SaleRentalSaleSection;
