import { Localizer } from "../../services/Localizer";
import { Area } from "../shared/Area";
import { MapCoordinates } from "../shared/MapCoordinates";
import { Media } from "../shared/Media";
import { OtherDetails } from "../shared/OtherDetails";
import { Property } from "../shared/Property";
import { SaleAndRentalSeason } from "../shared/SaleAndRentalSeason";

export interface Commercial {
    location: MapCoordinates
    property: Property
    area: Area
    bathroom: CommercialBathrooms
    commercial_general: CommercialGeneral
    sale_and_rental_seasons: SaleAndRentalSeason[]
    other_details: OtherDetails
    photos: Media
}

export interface CommercialBathrooms {
    num_bathrooms?: number
    num_accessible_bathrooms?: number
}

export interface CommercialGeneral {
    equipment_en?: string
    equipment_es?: string
    furniture_en?: string
    furniture_es?: string
    permits_en?: string
    permits_es?: string

    type_cafe_bar: boolean
    type_restaurant: boolean
    type_supermarket: boolean
    type_open_space: boolean
    type_retail: boolean
    type_office: boolean

    meters_indoor?: number
    meters_terrace?: number
    meters_storage?: number
    meters_refrigeration?: number

    has_reception: boolean
    num_office_rooms?: number

    parking_spaces_private_surface?: number
    parking_spaces_garage?: number

}

export function localizedEquipment(commercial: Commercial, localizer: Localizer): string {
    if (localizer.lang == 'es') {
        return commercial.commercial_general.equipment_es ?? ''
    } else {
        return commercial.commercial_general.equipment_en ?? ''
    }
}

export function localizedFurniture(commercial: Commercial, localizer: Localizer): string {
    if (localizer.lang == 'es') {
        return commercial.commercial_general.furniture_es ?? ''
    } else {
        return commercial.commercial_general.furniture_en ?? ''
    }
}

export function localizedPermits(commercial: Commercial, localizer: Localizer): string {
    if (localizer.lang == 'es') {
        return commercial.commercial_general.permits_es ?? ''
    } else {
        return commercial.commercial_general.permits_en ?? ''
    }
}

export function otherCommercialDetails(commercial: Commercial, lang: 'es' | 'en'): string {
    if (lang === 'en') {
        return commercial.other_details.other_details_english ?? ''
    } else {
        return commercial.other_details.other_details_spanish ?? ''
    }
}