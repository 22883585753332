import { MapCoordinates } from "../shared/MapCoordinates";

export enum PointOfInterestType {
    Beach = 'Beach',
    ShoppingArea = 'ShoppingArea',
    Market = 'Market',
    Supermarket = 'Supermarket',
    Sightseeing = 'Sightseeing',
    Pharmacy = 'Pharmacy',
    Entertainment = 'Entertainment',
    Highlight = 'Highlight',
    KidsArea = 'KidsArea',
    Healthcare = 'Healthcare'

}

export interface PointOfInterest {
    type: PointOfInterestType
    is_highlighted: boolean
    name_spanish: string
    name_english: string
    location: MapCoordinates
}

export function localizedPoiName(poi: PointOfInterest, lang: 'es' | 'en'): string {
    return lang === 'es' ? poi.name_spanish : poi.name_english
}