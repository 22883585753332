
import { AllSearchFunctions, SearchFunction } from '../../models/search/SearchFunction'
import { SearchPropertyType, searchPropertyTypesForSearchFunction } from '../../models/search/SearchPopertyType'
import { SearchPropertySubtype, searchPropertySubtypesForSearchPropertyType } from '../../models/search/SearchPropertySubtype'

export class SearchViewModel {

    public searchFunction: SearchFunction
    public searchPropertyType: SearchPropertyType
    public searchPropertySubtype: SearchPropertySubtype

    constructor(searchFunction: SearchFunction, searchPropertyType: SearchPropertyType, searchPropertySubtype: SearchPropertySubtype) {
        this.searchFunction = searchFunction
        this.searchPropertyType = searchPropertyType
        this.searchPropertySubtype = searchPropertySubtype
    }

    availableSearchFunctions(): SearchFunction[] {
        return AllSearchFunctions
    }

    availableSearchPropertyTypes(): SearchPropertyType[] {
        return searchPropertyTypesForSearchFunction(this.searchFunction)
    }

    availableSearchPropertySubtypes(): SearchPropertySubtype[] {
        return searchPropertySubtypesForSearchPropertyType(this.searchPropertyType)
    }

    settingSearchFunction(searchFunction: SearchFunction): SearchViewModel {
        if (searchFunction === this.searchFunction) {
            return this.clone()
        } else {
            const availablePropTypes = searchPropertyTypesForSearchFunction(searchFunction)
            const propType = availablePropTypes.find(p => { return p === this.searchPropertyType }) ?? availablePropTypes[0]

            const availablePropSubtypes = searchPropertySubtypesForSearchPropertyType(propType)
            const propSubtype = availablePropSubtypes.find(p => { return p === this.searchPropertySubtype }) ?? availablePropSubtypes[0]

            return new SearchViewModel(searchFunction, propType, propSubtype)
        }
    }

    settingSearchPropertyType(searchPropertyType: SearchPropertyType): SearchViewModel {
        if (searchPropertyType === this.searchPropertyType) {
            return this.clone()

        } else {
            const availablePropSubtypes = searchPropertySubtypesForSearchPropertyType(searchPropertyType)
            const propSubtype = availablePropSubtypes.find(p => { return p === this.searchPropertySubtype }) ?? availablePropSubtypes[0]

            return new SearchViewModel(this.searchFunction, searchPropertyType, propSubtype)
        }
    }

    settingSearchPropertySubtype(searchPropertySubtype: SearchPropertySubtype): SearchViewModel {
        if (searchPropertySubtype === this.searchPropertySubtype) {
            return this.clone()

        } else {
            const propSubtype = this.availableSearchPropertySubtypes().find(p => { return p === searchPropertySubtype }) ?? this.availableSearchPropertySubtypes()[0]

            return new SearchViewModel(this.searchFunction, this.searchPropertyType, propSubtype)
        }
    }

    equals(other: SearchViewModel): boolean {
        return this.searchFunction === other.searchFunction &&
            this.searchPropertyType === other.searchPropertyType &&
            this.searchPropertySubtype === other.searchPropertySubtype
    }

    clone(): SearchViewModel {
        return new SearchViewModel(this.searchFunction, this.searchPropertyType, this.searchPropertySubtype)
    }

    static default(): SearchViewModel {
        return new SearchViewModel(SearchFunction.All, SearchPropertyType.Home, SearchPropertySubtype.All)
    }
}