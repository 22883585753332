import React, { useEffect, useState } from 'react';

import Clusterer from '@react-google-maps/marker-clusterer/dist/Clusterer'
import { MapCoordinates } from '../../models/shared/MapCoordinates'
import { Marker } from '@react-google-maps/api';
import { getMarkerSpec } from './MarkerSpec'
import { MapMarkerType } from './MapMarkerType'

const SvgPathRegex = /d=['"]([\w\s\d\.\-]+)/g;

export interface MapMarkerProps {
    type: MapMarkerType
    subtype?: string

    title?: string
    location: MapCoordinates

    clusterer?: Clusterer.Clusterer
    isPrimary: boolean
}

function MapMarker(props: MapMarkerProps) {

    const colorsAndImageForMarker = getMarkerSpec(props)
    // const [iconPath, setIconPath] = useState<string | null>(null)
    const [hovering, setHovering] = useState(false)

    // useEffect(() => {
    //     if (colorsAndImageForMarker) {
    //         fetch(colorsAndImageForMarker?.iconSource, {cache: 'force-cache'})
    //             .then(r => r.text())
    //             .then(text => {
    //                 let iconPathAry = [...text.matchAll(SvgPathRegex)][0]

    //                 if (iconPathAry?.length === 2) {
    //                     setIconPath(iconPathAry[1])
    //                 } else {
    //                     console.error('Error parsing out pat from ' + text)
    //                 }
    //             })
    //             .catch(err => {
    //                 console.error(err)
    //             })

    //     }
    // }, [colorsAndImageForMarker?.iconSource])

    if (colorsAndImageForMarker == null) {
        return null
    }

    const icon: google.maps.Symbol | null = colorsAndImageForMarker.iconPath ? {
        path: colorsAndImageForMarker.iconPath,
        scale: props.isPrimary ? 0.03 : 0.02,
        strokeWeight: 1,
        strokeColor: colorsAndImageForMarker?.strokeColor,
        strokeOpacity: 1 ,
        fillColor: colorsAndImageForMarker?.fillColor,
        fillOpacity: 1
    } : null

    return (
        <Marker
            position={{ lat: props.location.latitude ?? 0, lng: props.location.longitude ?? 0}}
            clusterer={props.clusterer}
            title={props.title}
            opacity={1}
            zIndex={props.isPrimary ? 10: 1}
            icon={icon ?? undefined}
            onMouseOver={()=>{setHovering(true)}}
            onMouseOut={()=>{setHovering(false)}}/>

    );
}

export default MapMarker;
