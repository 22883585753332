import React, { useState } from 'react';
import './SelectDropdown.scss';
import { isMobile } from 'react-device-detect';

interface SelectDropdownOption {
    text: string;
    value: string;
    selected?: boolean;
}

// - Button Component

interface SelectButtonProps {
    text: string;
    onClick: () => void;
    onBlur: () => void;
}

function SelectButton(props: SelectButtonProps) {
    return (
        <button
            tabIndex={Math.floor(Math.random() * 1000)}
            onClick={(e) => {e.currentTarget.focus(); props.onClick()}}
            onBlur={props.onBlur}
            type='button'
            aria-haspopup='listbox'
            aria-expanded='true'
            aria-labelledby='listbox-label'
            className='SelectButton relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
            <span className='flex flex-auto items-center'>
                <span className='ml-3 block truncate text-gray-600 focus:text-indigo-900'>{props.text}</span>
            </span>
            <span className='ml-3 absolute inset-y-0 right-0 flex flex-none items-center pr-2 pointer-events-none'>
                <svg className='h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                    <path
                        fillRule='evenodd'
                        d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                        clipRule='evenodd'
                    />
                </svg>
            </span>
        </button>
    );
}

// - Popover

interface SelectPopoverProps {
    options: SelectDropdownOption[];
    onClick: (value: string) => void;
    onBlur: () => void;
}

function SelectPopover(props: SelectPopoverProps) {

    return (
        <div tabIndex={Math.floor(Math.random() * 1000)}
            className='SelectPopover absolute mt-1 w-full rounded-md bg-white shadow-lg'
            onBlur={() => { props.onBlur() }} >
            <ul
                tabIndex={Math.floor(Math.random() * 1000)}
                role='listbox'
                aria-labelledby='listbox-label'
                aria-activedescendant='listbox-item-3'
                className='max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                {props.options.map((o) => {
                    return (
                        <SelectPopoverOption
                            key={o.value}
                            option={o}
                            onClick={(v) => {
                                
                                props.onClick(v);
                                props.onBlur();
                            }}
                        />
                    );
                })}
            </ul>
        </div>
    );
}


// - Popover Option

interface SelectPopoverOptionProps {
    option: SelectDropdownOption;
    onClick: (value: string) => void;
}

function SelectPopoverOption(props: SelectPopoverOptionProps) {

    return (
        <li
            role='option'
            onMouseDown={(e) => {
                e.currentTarget.focus();
                props.onClick(props.option.value);
            }}
            className='SelectPopoverOption text-gray-900 hover:text-white hover:bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9'>
            <div className='flex items-center'>
                <span className='ml-3 block font-normal truncate'>{props.option.text}</span>
            </div>

            {props.option.selected && (
                <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
                    <svg className='h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                        <path fillRule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clipRule='evenodd' />
                    </svg>
                </span>
            )}
        </li>
    );
}


// - Entire Dropdown

interface SelectDropdownProps {
    options: SelectDropdownOption[];
    selectedOption?: string;
    onChange: (value: string) => void;
}

function SelectDropdown(props: SelectDropdownProps) {
    const [popoverShown, setPopoverShown] = useState(false);

    const selection = props.options.find((o) => {
        return props.selectedOption === null ? true : props.selectedOption === o.value;
    }) ?? { text: '', value: '' };

    return (
        <div className='SelectDropdown relative' onMouseLeave={() => {
            if (isMobile) {
                setPopoverShown(false);
            }
        }}>
            <SelectButton
                text={selection.text}
                onClick={() => {
                    setPopoverShown(true);
                }}
                onBlur={() => {
                    setPopoverShown(false);
                }}

            />
            {popoverShown && (
                <SelectPopover
                    options={props.options.map((o) => {
                        return {
                            text: o.text,
                            value: o.value,
                            selected: selection.value === o.value
                        };
                    })}
                    onClick={(v) => {
                        props.onChange(v);
                    }}
                    onBlur={() => {
                        setPopoverShown(false);
                    }}
                />
            )}
        </div>
    );
}

export default SelectDropdown;
