import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppDataContext, LocalizationContext } from '../../../App';
import IconTextLabelList from '../../../components/icon-text-label-list/IconTextLabelList';
import ImageGallery from '../../../components/image-gallery/ImageGallery';
import { PropertyListing } from '../../../models/list-data/PropertyListing';
import './PropertyEntry.scss';
import { PropertyEntryViewModel } from './PropertyEntryViewModel';

interface PropertyEntryProps {
    listing: PropertyListing
    maxElemsInRow: number
}

function PropertyEntry(props: PropertyEntryProps) {

    const appContext = useContext(AppDataContext)
    const loc = useContext(LocalizationContext).localizer

    const [scrolling, setScrolling] = useState(false)

    const viewModel = new PropertyEntryViewModel(props.listing, loc, appContext.modelData, appContext.search)
    return (
        <div className={`PropertyEntry relative w-full md:w-1/${props.maxElemsInRow} px-4 mb-6`} onMouseEnter={()=>{setScrolling(true)}} onMouseLeave={()=>{setScrolling(false)}}>
            <Link to={`/${viewModel.searchPath}/${viewModel.refNo}`}>
                <div className='PropertyEntryInner rounded shadow hover:shadow-md relative flex flex-col flex-nowrap items-stretch overflow-hidden'>
                    {viewModel.refNo &&
                        <div className='RefTag absolute top-2 right-2 z-10 font-mono text-xs font-medium px-2 py-1 rounded'>REF {viewModel.refNo}</div>
                    }
                    <div className="h-46">
                        <ImageGallery images={viewModel.images} shouldAutoscroll={scrolling} showNavButtons={false} showPageButtons={false} fillContents={true} />
                    </div>
                    
                    <div className='PropertyEntryText p-2 border-b'>
                        <div className='flex flex-row flex-nowrap justify-between '>
                            <h2 className='PropertyTitle cap font-heading text-indigo-600 mb-1'>{viewModel.titleText}</h2>
                            <p className='font-medium'>{viewModel.priceTag}</p>
                        </div>
                        <IconTextLabelList iconTextLabelList={viewModel.iconLabelSets} />


                    </div>
                    <p className="p-2 text-md leading-relaxed">{viewModel.descrText}</p>
                </div>
            </Link>
            { viewModel.labels && 
                        <div className='absolute top-1 -left-1 z-10 transform -rotate-12 w-72'>
                            { viewModel.labels.map((label, idx) => {
                                return <span key={idx} className='shadow-md rounded py-1 px-2 bg-indigo-100 text-center text-indigo-900 text-sm font-bold cap m-1 bg-white'>{label}</span>
                            })}
                        </div>
                    }

        </div>
    );
}

export default PropertyEntry;

