import React from 'react';
import IconTextLabel, { IconTextLabelProps } from './IconTextLabel';

export interface IconTextLabelListProps {
    iconTextLabelList: IconTextLabelProps[]
}

function IconTextLabelList(props: IconTextLabelListProps) {
    return (
        <div className='IconTextLabelList flex flex-nowrap flex-row justify-between'>
            {props.iconTextLabelList.map((iconTextLabel, idx) => {
                return <IconTextLabel {...iconTextLabel} key={idx} />
            })}
        </div>
    );
}

export default IconTextLabelList;
