import { IconColor, IconIntensity, IconSize } from "../../components/icon/IconStyle";
import { IconType } from "../../components/icon/IconType";
import { MapMarkerProps } from "../../components/map/MapMarker";
import { MapMarkerType } from "../../components/map/MapMarkerType";
import { PropertyListing } from "../../models/list-data/PropertyListing";
import { localizedPoiName } from '../../models/map-data/PointOfInterest';
import { localizedEquipment, localizedFurniture, localizedPermits, otherCommercialDetails } from "../../models/properties/Commercial";
import { HomeSeaAccess, localizedBedroomName, otherHomeDetails } from "../../models/properties/Home";
import { Parking } from "../../models/properties/Parking";
import { SearchPropertyType } from "../../models/search/SearchPopertyType";
import { MapCoordinates } from "../../models/shared/MapCoordinates";
import { MediaPhoto } from "../../models/shared/Media";
import { SaleAndRentalSeason } from "../../models/shared/SaleAndRentalSeason";
import { Season } from "../../models/shared/Season";
import { Localizer } from "../../services/Localizer";
import { ModelData } from "../../view-models/model-data/ModelData";
import { SearchViewModel } from "../../view-models/search-view-model/SearchViewModel";
import { PropertyDetailsSectionProps } from './PropertyDetailsSection';

export class PropertyDetailsViewModel {

    listing: PropertyListing

    mapCoordinates: MapCoordinates | null = null
    mapMarkerData: MapMarkerProps[] = []

    propTitle: string = ''

    propertySections: PropertyDetailsSectionProps[] = []

    neighborhoodDescription: string | null = null
    calmAreaDescription: string | null = null
    centralAreaDescription: string | null = null
    orientationDescription: string | null = null

    seaProximity: string[] = []
    poiProximity: { distance: number, title: string }[] = []

    images: MediaPhoto[] = []

    season: SaleAndRentalSeason | null = null
    parkings: Parking[] = []

    constructor(listing: PropertyListing, localizer: Localizer, modelData: ModelData, searchViewModel: SearchViewModel) {
        this.listing = listing

        this.computeTitle(localizer)
        this.computeMap(modelData)
        this.computeOverview(localizer)
        this.computeDetails(localizer)
        this.computeAmenities(localizer)
        this.computeAcessibility(localizer)
        this.computeImages()
        this.computeLocation(localizer, modelData)
        this.computeParking()

        const season = modelData.findSeason()

        if (season != null) {
            this.computeSaleAndRentalSeason(season)
        }
    }

    computeTitle(localizer: Localizer) {
        this.propTitle = this.listing.computedTitle(localizer)
    }

    computeMap(modelData: ModelData) {
        if (this.listing.propertyType === SearchPropertyType.Home || this.listing.propertyType === SearchPropertyType.Commercial) {
            this.mapCoordinates = this.listing.mapCoordinates
            let mapMarkerType = this.listing.mapMarkerType

            if (this.mapCoordinates && mapMarkerType) {
                this.mapMarkerData.push({ type: mapMarkerType, subtype: undefined, title: this.propTitle, location: this.mapCoordinates, isPrimary: true })
            }

            if (modelData.mapItems.pointsOfInterest) {
                this.mapMarkerData = this.mapMarkerData.concat(modelData.mapItems.pointsOfInterest.map(poi => {
                    return { type: MapMarkerType.poi, subtype: poi.type, title: poi.name_spanish, location: poi.location, isPrimary: false }
                }))
            }
        }
    }

    get showMap(): boolean {
        return this.mapCoordinates != null
    }

    computeOverview(localizer: Localizer) {

        if (this.listing.propertyType === SearchPropertyType.Home) {
            const home = this.listing.home!

            let overviewSection: PropertyDetailsSectionProps = {
                icon: {
                    style: {
                        color: IconColor.indigo,
                        intensity: IconIntensity.i600,
                        size: IconSize.l
                    },
                    type: IconType.home
                },
                title: localizer.k('property.title.overview'),
                attributes: [],
                paragraphs: []
            }

            overviewSection.attributes.push({ title: localizer.k('property.label.ref_no'), value: this.listing.refNo })


            overviewSection.attributes.push({ title: localizer.k('label.rooms'), value: localizer.s(localizedBedroomName(home)) })
            overviewSection.attributes.push({ title: localizer.k('property.label.home_floor'), value: localizer.k('property.label.home_floor.' + home.home_general.floor) })

            if (home.home_general.size_meters_built) {
                overviewSection.attributes.push({ title: localizer.k('property.label.meters_built'), value: `${home.home_general.size_meters_built} m²` })
            }

            if (home.home_general.size_meters_plot) {
                overviewSection.attributes.push({ title: localizer.k('property.label.meters_plot'), value: `${home.home_general.size_meters_plot} m²` })
            }

            if (home.rental_capacity.max_capacity_adults || home.rental_capacity.max_capacity_children) {
                overviewSection.attributes.push({ title: localizer.k('label.capacity_adults_children'), value: `${home.rental_capacity.max_capacity_adults ?? '0'} + ${home.rental_capacity.max_capacity_children ?? '0'}` })
            }

            if (overviewSection.attributes.length > 0 || overviewSection.paragraphs.length > 0) {
                this.propertySections.push(overviewSection)
            }

        } else if (this.listing.propertyType === SearchPropertyType.Commercial) {
            const comm = this.listing.commercial!

            let overviewSection: PropertyDetailsSectionProps = {
                icon: {
                    style: {
                        color: IconColor.indigo,
                        intensity: IconIntensity.i600,
                        size: IconSize.l
                    },
                    type: IconType.store
                },
                title: localizer.k('property.title.overview'),
                attributes: [],
                paragraphs: []
            }

            overviewSection.attributes.push({ title: localizer.k('property.label.ref_no'), value: this.listing.refNo })


            if (comm.commercial_general.meters_indoor) {
                overviewSection.attributes.push({ title: localizer.k('property.label.meters_indoor'), value: `${comm.commercial_general.meters_indoor} m²` })
            }

            if (comm.commercial_general.meters_terrace) {
                overviewSection.attributes.push({ title: localizer.k('property.label.meters_terrace'), value: `${comm.commercial_general.meters_terrace} m²` })
            }

            if (comm.commercial_general.meters_storage) {
                overviewSection.attributes.push({ title: localizer.k('property.label.meters_storage'), value: `${comm.commercial_general.meters_storage} m²` })
            }

            if (comm.commercial_general.meters_refrigeration) {
                overviewSection.attributes.push({ title: localizer.k('property.label.meters_refrigeration'), value: `${comm.commercial_general.meters_refrigeration} m²` })
            }

            if (comm.commercial_general.has_reception) {
                overviewSection.attributes.push({ title: localizer.k('property.label.has_reception'), value: localizer.k('prep.yes') })
            }

            if (comm.commercial_general.num_office_rooms) {
                overviewSection.attributes.push({ title: localizer.k('property.label.num_office_rooms'), value: `${comm.commercial_general.num_office_rooms}` })
            }

            if (comm.bathroom.num_bathrooms) {
                overviewSection.attributes.push({ title: localizer.k('property.label.bathrooms_standard'), value: `${comm.bathroom.num_bathrooms}` })
            }

            if (comm.bathroom.num_accessible_bathrooms) {
                overviewSection.attributes.push({ title: localizer.k('property.label.bathrooms_accessible'), value: `${comm.bathroom.num_accessible_bathrooms}` })
            }

            if (overviewSection.attributes.length > 0 || overviewSection.paragraphs.length > 0) {
                this.propertySections.push(overviewSection)
            }
        }
    }

    computeDetails(localizer: Localizer) {
        if (this.listing.propertyType === SearchPropertyType.Home) {
            const home = this.listing.home!

            let detailsSection: PropertyDetailsSectionProps = {
                icon: {
                    style: {
                        color: IconColor.indigo,
                        intensity: IconIntensity.i600,
                        size: IconSize.l
                    },
                    type: IconType.clipboard
                },
                title: localizer.k('property.title.details'),
                attributes: [],
                paragraphs: []
            }

            if (home.kitchen.num_kitchens_independent && home.kitchen.num_kitchens_independent > 1) {
                detailsSection.attributes.push({ title: localizer.k('property.label.kitchens_independent'), value: `${home.kitchen.num_kitchens_independent}` })
            }

            if (home.kitchen.num_kitchens_american && home.kitchen.num_kitchens_american > 1) {
                detailsSection.attributes.push({ title: localizer.k('property.label.kitchens_american'), value: `${home.kitchen.num_kitchens_american}` })
            }


            if (home.kitchen.num_kitchens_independent && home.kitchen.num_kitchens_independent === 1) {
                detailsSection.attributes.push({ title: localizer.k('property.label.kitchens_independent_s'), value: localizer.k('prep.yes') })
            }

            if (home.kitchen.num_kitchens_american && home.kitchen.num_kitchens_american === 1) {
                detailsSection.attributes.push({ title: localizer.k('property.label.kitchens_american_s'), value: localizer.k('prep.yes') })
            }


            if (home.kitchen.kitchen_has_dishwasher) {
                detailsSection.attributes.push({ title: localizer.k('property.label.kitchen_dishwasher'), value: localizer.k('prep.yes') })
            }

            if (home.kitchen.kitchen_has_pantry) {
                detailsSection.attributes.push({ title: localizer.k('property.label.kitchen_pantry'), value: localizer.k('prep.yes') })
            }

            if (home.kitchen.kitchen_has_washroom) {
                detailsSection.attributes.push({ title: localizer.k('property.label.kitchen_washroom'), value: localizer.k('prep.yes') })

                if (home.kitchen.kitchen_washroom_is_glassed_in) {
                    detailsSection.attributes.push({ title: localizer.k('property.label.kitchen_washroom_is_glassed_in'), value: localizer.k('prep.yes') })
                }
            }

            if (home.bathroom.num_bathrooms_full) {
                detailsSection.attributes.push({ title: localizer.k('property.label.bathrooms_full'), value: `${home.bathroom.num_bathrooms_full}` })
            }

            if (home.bathroom.num_bathrooms_halfbath) {
                detailsSection.attributes.push({ title: localizer.k('property.label.bathrooms_halfbath'), value: `${home.bathroom.num_bathrooms_halfbath}` })
            }

            if (detailsSection.attributes.length > 0 || detailsSection.paragraphs.length > 0) {
                this.propertySections.push(detailsSection)
            }

        } else if (this.listing.propertyType === SearchPropertyType.Commercial) {
            const comm = this.listing.commercial!

            let detailsSection: PropertyDetailsSectionProps = {
                icon: {
                    style: {
                        color: IconColor.indigo,
                        intensity: IconIntensity.i600,
                        size: IconSize.l
                    },
                    type: IconType.clipboard
                },
                title: localizer.k('property.title.details'),
                attributes: [],
                paragraphs: []
            }

            if (comm.commercial_general.type_cafe_bar) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_apt_for') + localizer.k('property.label.commercial_type.cafe'), value: localizer.k('prep.yes') })
            }

            if (comm.commercial_general.type_restaurant) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_apt_for') + localizer.k('property.label.commercial_type.restaurant'), value: localizer.k('prep.yes') })
            }

            if (comm.commercial_general.type_supermarket) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_apt_for') + localizer.k('property.label.commercial_type.supermarket'), value: localizer.k('prep.yes') })
            }

            if (comm.commercial_general.type_open_space) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_apt_for') + localizer.k('property.label.commercial_type.open_space'), value: localizer.k('prep.yes') })
            }

            if (comm.commercial_general.type_retail) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_apt_for') + localizer.k('property.label.commercial_type.retail'), value: localizer.k('prep.yes') })
            }

            if (comm.commercial_general.type_office) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_apt_for') + localizer.k('property.label.commercial_type.office'), value: localizer.k('prep.yes') })
            }

            if (comm.commercial_general.equipment_en || comm.commercial_general.equipment_es) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_equipment'), value: localizedEquipment(comm, localizer) })
            }

            if (comm.commercial_general.furniture_en || comm.commercial_general.furniture_es) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_furniture'), value: localizedFurniture(comm, localizer) })
            }

            if (comm.commercial_general.permits_en || comm.commercial_general.permits_es) {
                detailsSection.attributes.push({ title: localizer.k('property.label.commercial_permits'), value: localizedPermits(comm, localizer) })
            }

            let otherDetails = otherCommercialDetails(comm, localizer.lang)

            if (otherDetails.length > 0) {
                otherDetails.split('\n').filter(p => { return p.length > 1 }).forEach(p => {
                    detailsSection.paragraphs.push(p)
                })
            }

            if (detailsSection.attributes.length > 0 || detailsSection.paragraphs.length > 0) {
                this.propertySections.push(detailsSection)
            }
        }
    }


    computeAmenities(localizer: Localizer) {
        if (this.listing.propertyType === SearchPropertyType.Home) {
            const home = this.listing.home!

            let amenitiesSection: PropertyDetailsSectionProps = {
                icon: {
                    style: {
                        color: IconColor.indigo,
                        intensity: IconIntensity.i600,
                        size: IconSize.l
                    },
                    type: IconType.beach
                },
                title: localizer.k('property.title.amenities'),
                attributes: [],
                paragraphs: []
            }

            if (home.extras_and_amenities.num_swimming_pools && home.extras_and_amenities.num_swimming_pools > 0) {
                amenitiesSection.attributes.push({ title: localizer.k(home.extras_and_amenities.num_swimming_pools > 1 ? 'property.label.home_amenities.pools_plural' : 'property.label.home_amenities.pools_singular'), value: `${home.extras_and_amenities.num_swimming_pools}` })
            }

            if (home.extras_and_amenities.num_gardens && home.extras_and_amenities.num_gardens > 0) {
                amenitiesSection.attributes.push({ title: localizer.k(home.extras_and_amenities.num_gardens > 1 ? 'property.label.home_amenities.gardens_plural' : 'property.label.home_amenities.gardens_singular'), value: `${home.extras_and_amenities.num_gardens}` })
            }

            if ((home.extras_and_amenities.num_normal_terraces && home.extras_and_amenities.num_normal_terraces > 0) || (home.extras_and_amenities.num_ample_terraces && home.extras_and_amenities.num_ample_terraces > 0)) {
                let terraces = []

                if (home.extras_and_amenities.num_normal_terraces && home.extras_and_amenities.num_normal_terraces > 0) {
                    terraces.push(`${home.extras_and_amenities.num_normal_terraces} {${home.extras_and_amenities.num_normal_terraces > 1 ? 'property.label.home_amenities.terraces_plural' : 'property.label.home_amenities.terraces_singular'}}`)
                }

                if (home.extras_and_amenities.num_ample_terraces && home.extras_and_amenities.num_ample_terraces > 0) {
                    terraces.push(`${home.extras_and_amenities.num_ample_terraces} {${home.extras_and_amenities.num_ample_terraces > 1 ? 'property.label.home_amenities.ample_terraces_plural' : 'property.label.home_amenities.ample_terraces_singular'}}`)
                }

                let terraceCountText = terraces.join(' {prep.and} ')
                let terrraceSize = home.extras_and_amenities.meters_terrace > 0 ? ` {prep.of} ${home.extras_and_amenities.meters_terrace}m²` : ''

                amenitiesSection.attributes.push({ title: localizer.k('property.label.home_amenities.terraces_plural'), value: localizer.s(`${terraceCountText} ${terrraceSize}`) })
            }

            if (home.extras_and_amenities.num_solariums && home.extras_and_amenities.num_solariums > 0) {
                let solariumCountText = `${home.extras_and_amenities.num_solariums} {${home.extras_and_amenities.num_solariums > 1 ? 'property.label.home_amenities.solariums_plural' : 'property.label.home_amenities.solariums_singular'}}`
                let solariumSize = home.extras_and_amenities.meters_solarium > 0 ? ` {prep.of} ${home.extras_and_amenities.meters_solarium}m²` : ''

                amenitiesSection.attributes.push({ title: localizer.k(home.extras_and_amenities.num_solariums > 1 ? 'property.label.home_amenities.solariums_plural' : 'property.label.home_amenities.solariums_singular'), value: localizer.s(`${solariumCountText + solariumSize}`) })
            }

            let otherItems = []


            if (home.extras_and_amenities.has_ventilation_fan) {
                otherItems.push(`{property.label.home_amenities.ventilation_fan}`)
            }

            if (home.extras_and_amenities.has_tennis_court) {
                otherItems.push(`{property.label.home_amenities.tennis_court}`)
            }

            if (home.extras_and_amenities.has_barbequeue) {
                otherItems.push(`{property.label.home_amenities.barbequeue}`)
            }

            if (home.extras_and_amenities.has_patio) {
                otherItems.push(`{property.label.home_amenities.patio}`)
            }

            if (home.extras_and_amenities.has_kids_play_park) {
                otherItems.push(`{property.label.home_amenities.kids_play_park}`)
            }

            if (home.extras_and_amenities.has_terrace_awning) {
                otherItems.push(`{property.label.home_amenities.terrace_awning}`)
            }

            if (home.extras_and_amenities.has_padel_court) {
                otherItems.push(`{property.label.home_amenities.padel_court}`)
            }

            if (home.extras_and_amenities.has_deck_chairs) {
                otherItems.push(`{property.label.home_amenities.deck_chairs}`)
            }

            if (home.extras_and_amenities.has_storage) {
                otherItems.push(`{property.label.home_amenities.storage}`)
            }

            if (home.extras_and_amenities.has_porch) {
                otherItems.push(`{property.label.home_amenities.porch}`)
            }

            if (home.extras_and_amenities.has_terrace_furniture) {
                otherItems.push(`{property.label.home_amenities.terrace_furniture}`)
            }

            if (otherItems.length > 0) {
                amenitiesSection.attributes.push({ title: localizer.k('property.label.other_amenities'), value: localizer.s(`${otherItems.join(', ')}`) })
            }

            let otherDetails = otherHomeDetails(home, localizer.lang)

            if (otherDetails.length > 0) {
                amenitiesSection.paragraphs.push(otherDetails)
            }

            if (amenitiesSection.attributes.length > 0 || amenitiesSection.paragraphs.length > 0) {
                this.propertySections.push(amenitiesSection)
            }
        }
    }


    computeAcessibility(localizer: Localizer) {
        if (this.listing.propertyType === SearchPropertyType.Home) {
            const home = this.listing.home!


            let accessibilitySection: PropertyDetailsSectionProps = {
                icon: {
                    style: {
                        color: IconColor.indigo,
                        intensity: IconIntensity.i600,
                        size: IconSize.l
                    },
                    type: IconType.wheelchair
                },
                title: localizer.k('property.title.accessibility'),
                attributes: [],
                paragraphs: []
            }

            if (home.accessibility.has_lift) {
                accessibilitySection.attributes.push({ title: localizer.k('property.label.accessibility.has_lift'), value: localizer.k('prep.yes') })
            }

            if (home.accessibility.has_ramps) {
                accessibilitySection.attributes.push({ title: localizer.k('property.label.accessibility.has_ramps'), value: localizer.k('prep.yes') })
            }

            if (home.accessibility.has_full_wheelchair_access) {
                accessibilitySection.attributes.push({ title: localizer.k('property.label.accessibility.has_full_wheelchair_access'), value: localizer.k('prep.yes') })
            }

            if (accessibilitySection.attributes.length > 0 || accessibilitySection.paragraphs.length > 0) {
                this.propertySections.push(accessibilitySection)
            }

        }
    }

    computeLocation(localizer: Localizer, modelData: ModelData) {
        if (this.listing.propertyType === SearchPropertyType.Home || this.listing.propertyType === SearchPropertyType.Commercial) {
            const home = this.listing.home
            const comm = this.listing.commercial

            const area = (home?.area ?? comm?.area)

            if (area?.neighbourhood) {
                this.neighborhoodDescription = localizer.k('property.label.neighborhood').replace('NB', localizer.k('property.label.neighborhood.' + area?.neighbourhood))
            }

            if (area?.calm_area) {
                this.calmAreaDescription = localizer.k('property.label.area.is_calm')
            }

            if (area?.central_area) {
                this.centralAreaDescription = localizer.k('property.label.area.is_central')
            }

            if (home && home.extras_and_amenities.orientation) {
                this.orientationDescription = localizer.k('property.label.orientation_direction').replace('OR', localizer.k(`property.label.orientation_${home.extras_and_amenities.orientation}`))
            }

            // Sea access

            if (home && home.extras_and_amenities.access_mediterranean !== HomeSeaAccess.None) {
                this.seaProximity.push(localizer.k('property.label.sea_proximity.' + home?.extras_and_amenities.access_mediterranean).replace('SEA', localizer.k('sea.mediterranean')))
            }

            if (home && home.extras_and_amenities.access_mar_menor !== HomeSeaAccess.None) {
                this.seaProximity.push(localizer.k('property.label.sea_proximity.' + home?.extras_and_amenities.access_mar_menor).replace('SEA', localizer.k('sea.mar_menor')))
            }

            if (this.showMap && modelData.mapItems && modelData.mapItems.pointsOfInterest) {
                this.poiProximity = modelData.mapItems.pointsOfInterest.map(poi => {
                    return { distance: this.computeDistance(this.mapCoordinates!, poi.location), title: localizedPoiName(poi, localizer.lang) }
                }).sort((a, b) => {
                    return a.distance - b.distance
                }).filter((a) => {
                    return a.distance < 900
                })
            }
        }
    }

    computeDistance(propertyCoordinates: MapCoordinates, otherItem: MapCoordinates): number {
        const lat1 = propertyCoordinates.latitude
        const lon1 = propertyCoordinates.longitude

        const lat2 = otherItem.latitude
        const lon2 = otherItem.longitude

        if (lat1 == null || lon1 == null || lat2 == null || lon2 == null) {
            return Number.MAX_VALUE
        }

        const R = 6371e3; // metres
        const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // in metres

        return d
    }

    computeImages() {
        if (this.listing.propertyType === SearchPropertyType.Home) {
            const home = this.listing.home!
            this.images = home.media.photos

        } else if (this.listing.propertyType === SearchPropertyType.Commercial) {
            const comm = this.listing.commercial!
            this.images = comm.photos.photos
        }
    }

    computeSaleAndRentalSeason(season: Season) {
        if (this.listing.propertyType === SearchPropertyType.Home || this.listing.propertyType === SearchPropertyType.Commercial) {
            this.season = this.listing.currentSaleAndRentalSeason(season)
        }
    }

    computeParking() {
        if (this.listing.propertyType === SearchPropertyType.Home) {
            const home = this.listing.home!
            this.parkings = home.associated_parkings
        }
    }

    get hasImages(): boolean {
        return this.images.length > 0
    }
}
