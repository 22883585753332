
export enum IconColor {
    red = 'red',
    yellow = 'yellow',
    green = 'green',
    blue = 'blue',
    indigo = 'indigo',
    purple = 'purple',
    pink = 'pink'
}

export enum IconIntensity {
    i100 = '100',
    i200 = '200',
    i300 = '300',
    i400 = '400',
    i500 = '500',
    i600 = '600',
    i700 = '700',
    i800 = '800',
}

export enum IconSize {
    s = '3',
    m = '4',
    l = '5',
    xl = '6'
}

export interface IconStyle {
    color: IconColor
    intensity: IconIntensity
    size: IconSize
}