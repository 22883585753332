import { IconTextLabelProps } from "../../../components/icon-text-label-list/IconTextLabel";
import { IconColor, IconIntensity, IconSize, IconStyle } from "../../../components/icon/IconStyle";
import { IconType } from "../../../components/icon/IconType";
import { PropertyListing } from "../../../models/list-data/PropertyListing";
import { localizedBedroomName, localizedBedroomNo, localizedFloor, showFloorInSummary, showMetersBuiltInSummary, showMetersPlotInSummary } from "../../../models/properties/Home";
import { SearchFunction } from "../../../models/search/SearchFunction";
import { SearchPropertyType } from "../../../models/search/SearchPopertyType";
import { SearchPropertySubtype } from "../../../models/search/SearchPropertySubtype";
import { MediaPhoto } from "../../../models/shared/Media";
import { Localizer } from "../../../services/Localizer";
import { ModelData } from "../../../view-models/model-data/ModelData";
import { SearchViewModel } from "../../../view-models/search-view-model/SearchViewModel";

export class PropertyEntryViewModel {

    refNo: string
    titleText: string
    iconLabelSets: IconTextLabelProps[]
    searchFunction: SearchFunction
    searchPropertyType: SearchPropertyType
    searchPropetySubtype: SearchPropertySubtype
    priceTag: string
    descrText: string
    images: MediaPhoto[] = []
    labels: string[]

    constructor(listing: PropertyListing, localizer: Localizer, modelData: ModelData, searchViewModel: SearchViewModel) {
        this.refNo = listing.refNo
        this.titleText = listing.computedTitle(localizer)
        this.iconLabelSets = PropertyEntryViewModel.IconLabelsFromListing(listing, localizer)
        this.searchFunction = searchViewModel.searchFunction
        this.searchPropertyType = searchViewModel.searchPropertyType
        this.searchPropetySubtype = searchViewModel.searchPropertySubtype
        this.priceTag = localizer.s(listing.previewPriceLocalized(searchViewModel.searchFunction, modelData.findSeason()))
        this.descrText = listing.computedSummary(localizer)
        this.images = PropertyEntryViewModel.PreviewPhotosFromListing(listing)
        this.labels = listing.computedLabels(localizer)
    }

    get searchPath(): string {
        return `${this.searchFunction}/${this.searchPropertyType}/${this.searchPropetySubtype}`
    }

    static PreviewPhotosFromListing(listing: PropertyListing): MediaPhoto[] {
        let photos: MediaPhoto[] = []

        if (listing.propertyType === SearchPropertyType.Home) {
            let home = listing.home!
            photos = home.media.photos

        } else if (listing.propertyType === SearchPropertyType.Commercial) {
            let comm = listing.commercial!
            photos = comm.photos.photos
        }

        return photos
    }

    static IconLabelsFromListing(listing: PropertyListing, localizer: Localizer): IconTextLabelProps[] {
        let list: IconTextLabelProps[] = []

        let iconStyle: IconStyle = {
            color: IconColor.indigo,
            intensity: IconIntensity.i600,
            size: IconSize.m
        }

        if (listing.propertyType === SearchPropertyType.Home) {
            let home = listing.home!

            list.push({
                icon: {
                    style: iconStyle,
                    type: IconType.bed
                },
                text: localizer.s(localizedBedroomNo(home)),
                iconAfter: false,
                altLabel: localizer.s(`${localizedBedroomName(home)}`)
            })

            if (showMetersPlotInSummary(home)) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.meters
                    },
                    text: `${home.home_general.size_meters_plot}m²`,
                    iconAfter: false,
                    altLabel: `${home.home_general.size_meters_plot} {property.label.meters_plot}`
                })

            } else if (showMetersBuiltInSummary(home)) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.meters
                    },
                    text: `${home.home_general.size_meters_built}m²`,
                    iconAfter: false,
                    altLabel: `${localizedBedroomName(home)} {property.label.meters_built}`
                })
            }

            if (showFloorInSummary(home)) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.elevator
                    },
                    text: localizer.s(`${localizedFloor(home)}`),
                    iconAfter: false,
                    altLabel: localizer.s(`${localizedFloor(home)} {property.label.home_floor}`)
                })
            }

            if (home.bathroom.num_bathrooms_full) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.bathroom
                    },
                    text: `${home.bathroom.num_bathrooms_full}`,
                    iconAfter: false,
                    altLabel: `${home.bathroom.num_bathrooms_full} {property.label.bathrooms_full}`
                })
            }

            if (home.bathroom.num_bathrooms_halfbath) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.halfbath
                    },
                    text: `${home.bathroom.num_bathrooms_halfbath}`,
                    iconAfter: false,
                    altLabel: `${home.bathroom.num_bathrooms_halfbath} {property.label.bathrooms_halfbath}`
                })
            }
        } else if (listing.propertyType === SearchPropertyType.Commercial) {
            let comm = listing.commercial!

            if (comm.commercial_general.type_cafe_bar) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.coffee
                    },
                    text: localizer.k('property.label.commercial_type.cafe'),
                    iconAfter: false,
                    altLabel: localizer.k('property.label.commercial_type.cafe')
                })
            }

            if (comm.commercial_general.type_restaurant) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.cutlery
                    },
                    text: localizer.k('property.label.commercial_type.restaurant'),
                    iconAfter: false,
                    altLabel: localizer.k('property.label.commercial_type.restaurant')
                })
            }

            if (comm.commercial_general.type_supermarket) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.store
                    },
                    text: localizer.k('property.label.commercial_type.supermarket'),
                    iconAfter: false,
                    altLabel: localizer.k('property.label.commercial_type.supermarket')
                })
            }

            if (comm.commercial_general.type_open_space) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.doorOpen
                    },
                    text: localizer.k('property.label.commercial_type.open_space'),
                    iconAfter: false,
                    altLabel: localizer.k('property.label.commercial_type.open_space')
                })
            }

            if (comm.commercial_general.type_retail) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.cashRegister
                    },
                    text: localizer.k('property.label.commercial_type.retail'),
                    iconAfter: false,
                    altLabel: localizer.k('property.label.commercial_type.retail')
                })
            }

            if (comm.commercial_general.type_office) {
                list.push({
                    icon: {
                        style: iconStyle,
                        type: IconType.briefcase
                    },
                    text: localizer.k('property.label.commercial_type.office'),
                    iconAfter: false,
                    altLabel: localizer.k('property.label.commercial_type.office')
                })
            }
        }

        return list
    }

}