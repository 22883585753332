import React, { useContext, useState } from 'react';
import { LocalizationContext } from '../../../App';
import SearchForm from '../../../components/search-form/SearchForm';

import HeaderImg1 from '../../../assets/images/header/iStock-1072228088-r.jpg'
import HeaderImg2 from '../../../assets/images/header/iStock-1072228116-r.jpg'
import HeaderImg3 from '../../../assets/images/header/iStock-1184335209-r.jpg'
import HeaderImg4 from '../../../assets/images/header/iStock-1196092946-r.jpg'

import './HomeHero.scss'

const images = [HeaderImg1, HeaderImg2, HeaderImg3, HeaderImg4]


function HomeHero() {

    const [titleIdx, setTitleIdx] = useState(Math.floor(Math.random()*4))
    const [imageIdx, setImageIdx] = useState(Math.floor(Math.random()*4))

    const loc = useContext(LocalizationContext).localizer

    return (
        <section className='HomeHero bg-cover lg:bg-center'>
            <div className='flex flex-col relative pt-12 pb-8 md:pt-18 md:pb-16 px-8 md:px-16'>
                <div className='background-image absolute inset-0 bg-black rounded overflow-hidden' style={{background: `linear-gradient(155deg, rgba(9,80,121, 0.5) 10%, rgba(255, 255, 255, 0) 70%), url("${images[imageIdx]}") no-repeat center top / cover`}}></div>
                <div className='z-10 flex flex-1 w-full'>
                    <div className='flex flex-col md:flex-row w-full items-stretch'>
                        <div className='w-full lg:text-left text-white'>
                            <h2 className='tagline text-2xl md:text-5xl md:max-w-2xl leading-loose text-center md:text-left font-bold font-heading mb-6 md:mb-32'>{ loc.k(`home.hero.tagline${titleIdx}`) }</h2>
                            <div className='search-form-container p-4 rounded-xl shadow-md flex items-center mt-4 full-width'>
                                <SearchForm useSearchButton={true} useAdvancedFilter={false} onSearchSelectionChanged={() => {}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeHero;
