import React, { useContext } from 'react';
import { LocalizationContext } from '../../../App';
import HomeAboutSection, { HomeAboutSectionProps } from './HomeAboutSection';

import HomeLaMangaImage from '../../../assets/images/about/lamanga_shrunk.jpg';
import HomeHistoryImage from '../../../assets/images/about/Picture15.jpg'

function HomeAbout() {

    const loc = useContext(LocalizationContext).localizer

    const sections: HomeAboutSectionProps[] = [
        {
            title: loc.k('home.about.about-la-manga-header'),
            image: HomeLaMangaImage,
            paragraphs: [loc.k('home.about.about-la-manga-body-1'), loc.k('home.about.about-la-manga-body-2')]
        },
        {
            title: loc.k('home.about.about-history-header'),
            image: HomeHistoryImage,
            paragraphs: [loc.k('home.about.about-history-body-1'), loc.k('home.about.about-history-body-2')]
        },

    ]

    return (
        <section className='HomeAbout py-4 md:py-8'>
            <div className='text-center'>
                <h2 className='text-3xl text-center mt-2 mb-12 font-semibold font-heading'>{loc.k('home.about.header')}</h2>
            </div>
            <div className='flex flex-wrap max-w-6xl -mx-4 md:mx-auto -mb-8'>
                {sections.map((s, i) => {
                    return <HomeAboutSection key={i} title={s.title} image={s.image} paragraphs={s.paragraphs} />
                })}
            </div>
        </section>
    );
}

export default HomeAbout;
