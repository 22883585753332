import React, { useContext, useState, useEffect } from 'react';
import { SearchFunction } from '../../models/search/SearchFunction';
import { SearchPropertyType } from '../../models/search/SearchPopertyType';
import { SearchPropertySubtype } from '../../models/search/SearchPropertySubtype';
import SelectDropdown from '../select-dropdown/SelectDropdown';
import { AppDataContext, LocalizationContext } from '../../App';
import GenericButton from '../button/GenericButton'
import { useHistory } from 'react-router-dom';
import { SearchViewModel } from '../../view-models/search-view-model/SearchViewModel';

interface SearchFormProps {
    useSearchButton: boolean;
    useAdvancedFilter: boolean;

    onSearchSelectionChanged(searchFunc: SearchFunction, searchPropType: SearchPropertyType, searchPropSubtype: SearchPropertySubtype): void;
}

function SearchForm(props: SearchFormProps) {

    const loc = useContext(LocalizationContext).localizer
    const appContext = useContext(AppDataContext);
    const [dedicatedSearchParams, setDedicatedSearchParams] = useState(appContext.search)
    const history = useHistory()

    useEffect(() => {
        if (!props.useSearchButton && !dedicatedSearchParams.equals(appContext.search)) {
            setDedicatedSearchParams(appContext.search)
        }
    }, [appContext.search, dedicatedSearchParams, setDedicatedSearchParams, props.useSearchButton])

    const searchFunctions = dedicatedSearchParams.availableSearchFunctions().map((f) => {
        return {
            value: f,
            text: loc.k(`search.searchFunction.${f}`)
        };
    });

    const searchPropertyTypes = dedicatedSearchParams.availableSearchPropertyTypes().map((t) => {
        return {
            value: t,
            text: loc.k(`search.searchPropertyType.${t}`)
        }
    })

    const searchPropertySubtypes = dedicatedSearchParams.availableSearchPropertySubtypes().map((s) => {
        return {
            value: s,
            text: loc.k(`search.searchPropertySubtype.${s}`)
        }
    })

    function setSearchFunction(sf: string) {
        if (!props.useSearchButton) {
            performNavigation(dedicatedSearchParams.settingSearchFunction(sf as SearchFunction), true)
        } else {
            setDedicatedSearchParams(dedicatedSearchParams.settingSearchFunction(sf as SearchFunction))
        }
    }

    function setSearchPropertyType(st: string) {
        if (!props.useSearchButton) {
            performNavigation(dedicatedSearchParams.settingSearchPropertyType(st as SearchPropertyType), true)
        } else {
            setDedicatedSearchParams(dedicatedSearchParams.settingSearchPropertyType(st as SearchPropertyType))
        }
    }

    function setSearchPropertySubtype(sst: string) {
        if (!props.useSearchButton) {
            performNavigation(dedicatedSearchParams.settingSearchPropertySubtype(sst as SearchPropertySubtype), true)
        } else {
            setDedicatedSearchParams(dedicatedSearchParams.settingSearchPropertySubtype(sst as SearchPropertySubtype))
        }
    }

    function performNavigation(searchParams: SearchViewModel, inPlace: boolean) {
        if (inPlace) {
            history.replace(`/${searchParams.searchFunction}/${searchParams.searchPropertyType}/${searchParams.searchPropertySubtype}`)
        } else {
            history.push(`/${searchParams.searchFunction}/${searchParams.searchPropertyType}/${searchParams.searchPropertySubtype}`)
        }
    }

    return (
        <form className='SearchForm w-full'>
            <div className='flex flex-col md:flex-row'>
                <div className='flex-auto md:mr-4 mb-2 md:mb-0'>
                    <SelectDropdown selectedOption={dedicatedSearchParams.searchFunction} options={searchFunctions} onChange={(v) => {
                        setSearchFunction(v as SearchFunction);

                    }} />
                </div>
                <div className='flex-auto md:mr-4 mb-2 md:mb-0'>
                    <SelectDropdown selectedOption={dedicatedSearchParams.searchPropertyType} options={searchPropertyTypes} onChange={(v) => {
                        setSearchPropertyType(v as SearchPropertyType);

                    }} />
                </div>
                <div className={'flex-auto' + (props.useSearchButton ? ' md:mr-4 mb-2 md:mb-0' : '')}>
                    <SelectDropdown selectedOption={dedicatedSearchParams.searchPropertySubtype} options={searchPropertySubtypes} onChange={(v) => {
                        setSearchPropertySubtype(v as SearchPropertySubtype);

                    }} />
                </div>
                {props.useSearchButton &&
                    <GenericButton text={"Search"} onClick={() => { performNavigation(dedicatedSearchParams, false) }} />
                }
            </div>
        </form>
    );
}

export default SearchForm;
