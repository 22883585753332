import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from '../../App';
import { SearchFunction } from '../../models/search/SearchFunction';
import GenericButton from '../button/GenericButton';

interface NavigationProps {
    onLanguageChange: () => void
}

function Navigation(props: NavigationProps) {

    const loc = useContext(LocalizationContext).localizer

    const [menuHidden, setMenuHidden] = useState(true);

    return (
        <nav className='Navigation flex flex-wrap items-center justify-between py-4'>
            <div className='flex flex-shrink-0 mr-10'>
                <Link to='/' className='text-xl text-indigo-600 font-semibold font-heading'>
                    Inmobiliaria Euromar
                </Link>
            </div>

            <div className='block lg:hidden'>
                <button
                    onClick={() => {
                        setMenuHidden(!menuHidden);
                    }}
                    className='navbar-burger flex items-center py-2 px-3 text-indigo-500 rounded border border-indigo-500'>
                    <svg className='fill-current h-3 w-3' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                        <title>{loc.k('navigation.menu')}</title>
                        <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z'></path>
                    </svg>
                </button>
            </div>

            <div className={'navbar-menu lg:flex lg:flex-grow lg:items-center w-full lg:w-auto' + (menuHidden ? ' hidden' : '')}>
                <div className='lg:mr-auto'>
                    <Link to={'/' + SearchFunction.Sale} className='block lg:inline-block mt-4 lg:mt-0 mr-10 text-purple-600 hover:text-indigo-900'>
                        {loc.k('navigation.buy')}
                    </Link>
                    <Link to={'/' + SearchFunction.Rental} className='block lg:inline-block mt-4 lg:mt-0 mr-10 text-purple-600 hover:text-indigo-900'>
                        {loc.k('navigation.rent')}
                    </Link>
                    <Link to='/policy/rental' className='block lg:inline-block mt-4 lg:mt-0 mr-10 text-purple-600 hover:text-indigo-900'>
                        {loc.k('navigation.rental-conditions')}
                    </Link>
                </div>

                <div>
                    <GenericButton text={loc.lang == 'es' ? 'English' : 'Español'} onClick={()=>{ console.log('click'); props.onLanguageChange() }}/>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;
