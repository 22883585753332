import React from 'react';
import { PropertyListing } from '../../models/list-data/PropertyListing';
import PropertyEntry from '../../views/property-list/property-entry/PropertyEntry';

interface SearchListProps {
    propertyListings: PropertyListing[]
}

function SearchList(props: SearchListProps) {
    return (
        <div className='SearchList flex flex-wrap -mx-4 -mb-8 overflow-y-auto overflow-x-hidden max-h-full'>
            {props.propertyListings.map((p, i) => <PropertyEntry key={i} listing={p} maxElemsInRow={2} />)}
        </div>
    );
}

export default SearchList;
