import { SaleAndRentalSeason } from "../../models/shared/SaleAndRentalSeason";
import { SaleRentalRentalSectionProps } from './SaleRentalRentalSection'

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

interface MonthlyPrice {
    full: number | null
}

interface BiMonthlyPrice {
    firstHalf: number | null
    secondHalf: number | null
}

export class SaleRentalViewModel {

    showSale: boolean
    showRental: boolean

    shownRentalMonthIndices: number[] = []
    
    shownRentalRows = {
        daily30days: false,
        daily14days: false,
        daily7days: false,
        daily2weeks: false,
        daily1Week: false
    }

    daily30days: MonthlyPrice[] = []
    daily14days: MonthlyPrice[] = []
    daily7days: MonthlyPrice[] = []

    daily2weeks: BiMonthlyPrice[] = []
    daily1Week: BiMonthlyPrice[] = []

    rentalYear: string | null = null
    rentalSixMonths: string | null = null

    salePriceOriginal: number | null = null
    salePriceDiscounted: number | null = null

    saleIncludedItems: string[] = []
    saleAnnotations: string[] = []

    constructor(season: SaleAndRentalSeason) {
        this.showSale = season.is_for_sale
        this.showRental = season.is_for_rental_general

        const months = [season.rental_jan, season.rental_feb, season.rental_mar, season.rental_apr,
        season.rental_may, season.rental_jun, season.rental_jul, season.rental_aug,
        season.rental_sep, season.rental_oct, season.rental_nov, season.rental_dec]
        
        months.forEach((month, idx) => {
            if (month.is_shown) {
                this.shownRentalMonthIndices.push(idx)

                this.daily30days.push({full: month.daily_30_days ?? null})
                this.daily14days.push({full: month.daily_14_days ??null})
                this.daily7days.push({full: month.daily_7_days ?? null})

                this.daily2weeks.push({firstHalf: month.daily_two_weeks_1st_quarter ?? null, secondHalf: month.daily_two_weeks_2nd_quarter ?? null})
                this.daily1Week.push({firstHalf: month.daily_one_week_1st_quarter ?? null, secondHalf: month.dailiy_one_week_2nd_quarter ?? null})

                if (month.daily_30_days) {
                    this.shownRentalRows.daily30days = true
                }

                if (month.daily_14_days) {
                    this.shownRentalRows.daily14days = true
                }

                if (month.daily_7_days) {
                    this.shownRentalRows.daily7days = true
                }

                if (month.daily_two_weeks_1st_quarter || month.daily_two_weeks_2nd_quarter) {
                    this.shownRentalRows.daily2weeks = true
                }

                if (month.daily_one_week_1st_quarter || month.dailiy_one_week_2nd_quarter) {
                    this.shownRentalRows.daily1Week = true
                }

            } else {
                this.daily30days.push({full: null})
                this.daily14days.push({full: null})
                this.daily7days.push({full: null})

                this.daily2weeks.push({firstHalf: null, secondHalf: null})
                this.daily1Week.push({firstHalf: null, secondHalf: null})
            }
        });

        this.salePriceOriginal = season.sale_price_original ?? null
        this.salePriceDiscounted = season.sale_price_discounted ?? null

        if (season.sale_includes_furniture === true) {
            this.saleIncludedItems.push('property.label.sale.furniture_included')
        }
        
        if (season.sale_includes_home_appliances === true) {
            this.saleIncludedItems.push('property.label.sale.home_appliances_included')
        }

        if (season.sale_includes_home_goods === true) {
            this.saleIncludedItems.push('property.label.sale.home_goods_included')
        }

        if (season.sale_offers_payment_plans === true) {
            this.saleAnnotations.push('property.label.sale.offers_payment_plans')
        }

        if (season.sale_offers_mortgage_subrogation === true) {
            this.saleAnnotations.push('property.label.sale.offers_mortgage_subrogation')
        }

        this.rentalYear = season.rental_price_year ? Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(season.rental_price_year) : null
        this.rentalSixMonths = season.rental_price_six_months ? Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(season.rental_price_six_months) : null
    }

    rentalTable(): SaleRentalRentalSectionProps {
        let props: SaleRentalRentalSectionProps = {
            headerRow: {
                header: '{property.label.rental.price_for_period}',
                isSplit: false,
                values: months
                    .filter((m, idx) => { return this.shownRentalMonthIndices.includes(idx) })
                    .map(m => { return `{property.label.month.${m}}`})
            },
            bodyRows: [],
            rentalYear: this.rentalYear,
            rentalSixMonths: this.rentalSixMonths
        }

        if (this.shownRentalRows.daily30days) {
            props.bodyRows.push({
                header: '{property.label.rental.price_30_nights}',
                isSplit: false,
                values: this.daily30days
                    .filter((m, idx) => { return this.shownRentalMonthIndices.includes(idx) })
                    .map(m => {return m.full == null ? '' : (m.full * 30).toString()
                })
            })
        }

        if (this.shownRentalRows.daily14days) {
            props.bodyRows.push({
                header: '{property.label.rental.price_14_nights}',
                isSplit: false,
                values: this.daily14days
                    .filter((m, idx) => { return this.shownRentalMonthIndices.includes(idx) })
                    .map(m => {return m.full == null ? '' : (m.full * 14).toString()
                })
            })
        }

        if (this.shownRentalRows.daily7days) {
            props.bodyRows.push({
                header: '{property.label.rental.price_7_nights}',
                isSplit: false,
                values: this.daily14days
                    .filter((m, idx) => { return this.shownRentalMonthIndices.includes(idx) })
                    .map(m => {return m.full == null ? '' : (m.full * 7).toString()
                })
            })
        }

        if (this.shownRentalRows.daily2weeks) {
            props.bodyRows.push({
                header: '{property.label.rental.price_two_weeks}',
                isSplit: true,
                values: this.daily2weeks
                    .filter((m, idx) => { return this.shownRentalMonthIndices.includes(idx) })
                    .flatMap((m: BiMonthlyPrice) => {return [m.firstHalf, m.secondHalf]})
                    .map(m => {return m == null ? '' : (m * 14).toString()
                })
                    
            })
        }

        if (this.shownRentalRows.daily1Week) {
            props.bodyRows.push({
                header: '{property.label.rental.price_one_week}',
                isSplit: true,
                values: this.daily1Week
                    .filter((m, idx) => { return this.shownRentalMonthIndices.includes(idx) })
                    .flatMap((m: BiMonthlyPrice) => {return [m.firstHalf, m.secondHalf]})
                    .map(m => {return m == null ? '' : (m * 7).toString()
                })
                    
            })
        }

        

        return props
    }
}