import React from 'react';
import HomeHero from './home-hero/HomeHero';
import HomeAbout from './home-about/HomeAbout';
import HomeSteps from './home-steps/HomeSteps';
import HomeHighlights from './home-highlights/HomeHighlights';

function Home() {
    return (
        <div className='Home'>
            <HomeHero />
            <HomeHighlights />
            <HomeSteps />
            <HomeAbout />
        </div>
    );
}

export default Home;
