import React from 'react';
import Icon, { IconProps } from '../icon/Icon';

export interface IconTextLabelProps {
    icon: IconProps
    text: string
    iconAfter: boolean
    altLabel: string
}

function IconTextLabel(props: IconTextLabelProps) {
    return (
        <div className="IconTextLabel flex flex-row flex-nowrap items-center" role="img text" aria-label={props.altLabel}>
            {props.iconAfter === true && <div className="TextLabel cap">{props.text}</div>}
            <Icon type={props.icon.type} style={props.icon.style} />
            {props.iconAfter === false && <div className="TextLabel ml-2 cap">{props.text}</div>}
        </div>
    )
}

export default IconTextLabel