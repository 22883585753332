import { SearchPropertyType } from "../search/SearchPopertyType";
import { SearchPropertySubtype } from "../search/SearchPropertySubtype";
import { Area } from "../shared/Area";
import { MapCoordinates } from "../shared/MapCoordinates";
import { Media } from "../shared/Media";
import { OtherDetails } from "../shared/OtherDetails";
import { Property } from "../shared/Property";
import { SaleAndRentalSeason } from "../shared/SaleAndRentalSeason";
import { Parking } from './Parking';
import { Labels } from '../shared/Labels';
export interface Home {
    location: MapCoordinates
    property: Property
    area: Area
    sale_and_rental_seasons: SaleAndRentalSeason[]
    home_general: HomeGeneral
    bathroom: HomeBathroom
    kitchen: HomeKitchen
    extras_and_amenities: HomeAmenities
    other_details: OtherDetails
    rental_capacity: HomeCapacity
    accessibility: HomeAccessibility
    media: Media
    id: string
    labels: Labels
    associated_parkings: Parking[]
}

export interface HomeGeneral {
    bedrooms: HomeBedrooms

    floor: HomeFloors

    is_apartment: boolean
    is_attic: boolean
    is_bungalow: boolean
    is_duplex: boolean
    is_villa: boolean

    size_meters_built?: number
    size_meters_plot?: number
}

export interface HomeBathroom {
    num_bathrooms_full?: number
    num_bathrooms_halfbath?: number
}

export interface HomeAccessibility {
    has_lift: boolean
    has_ramps: boolean
    has_full_wheelchair_access: boolean
}

export interface HomeKitchen {
    kitchen_has_dishwasher: boolean
    kitchen_has_pantry: boolean
    kitchen_has_washroom: boolean
    kitchen_washroom_is_glassed_in: boolean
    num_kitchens_independent?: number
    num_kitchens_american?: number
}

export interface HomeCapacity {
    max_capacity_adults?: number
    max_capacity_children?: number
}

export enum HomeSeaAccess {
    None = "None",
    Nearby = "Nearby",
    Views = "Views",
    PanoramicViews = "PanoramicViews",
    FirstLine = "FirstLine"
}

export enum HomeOrientation {
    North = "North",
    South = "South",
    East = "East",
    West = "West"
}

export interface HomeAmenities {
    num_swimming_pools?: number
    num_gardens?: number
    num_normal_terraces?: number
    num_ample_terraces?: number
    num_solariums?: number
    has_patio: boolean
    has_kids_play_park: boolean
    has_ventilation_fan: boolean
    has_tennis_court: boolean
    has_padel_court: boolean
    has_barbequeue: boolean
    has_deck_chairs: boolean
    has_porch: boolean
    has_storage: boolean
    access_mediterranean: HomeSeaAccess
    access_mar_menor: HomeSeaAccess
    orientation?: HomeOrientation
    has_terrace_awning: boolean
    has_terrace_furniture: boolean
    meters_solarium: number
    meters_terrace: number
}


export enum HomeFloors {
    BasementSotano = "BasementSotano",
    SemiBasementSemiSotano = "SemiBasementSemiSotano",
    GroundFloorPlantaBaja = "GroundFloorPlantaBaja",
    MezzanineEntresuelo = "MezzanineEntresuelo",
    First = "First",
    Second = "Second",
    Third = "Third",
    Fourth = "Fourth",
    Fifth = "Fifth",
    Sixth = "Sixth",
    Seventh = "Seventh",
    Eighth = "Eighth",
    Ninth = "Ninth",
    Tenth = "Tenth",
    Eleventh = "Eleventh",
    Twelfth = "Twelfth",
    Thirteenth = "Thirteenth",
    Fourteenth = "Fourteenth",
    Fifteenth = "Fifteenth",
    Sixteenth = "Sixteenth",
    Seventeenth = "Seventeenth",
    Eighteenth = "Eighteenth",
    Nineteenth = "Nineteenth",
    Twentieth = "Twentieth"
}

export enum HomeBedrooms {
    Studio = "Studio",
    OneBedroom = "OneBedroom",
    TwoBedrooms = "TwoBedrooms",
    ThreeBedrooms = "ThreeBedrooms",
    FourBedrooms = "FourBedrooms",
    FiveBedrooms = "FiveBedrooms",
    SixOrMoreBedrooms = "SixOrMoreBedrooms",
}

export function showMetersPlotInSummary(home: Home): boolean {
    if (home.home_general.size_meters_built == null && home.home_general.size_meters_plot != null) {
        return true
    } else if (home.home_general.size_meters_built != null && home.home_general.size_meters_plot != null) {
        if (home.home_general.is_bungalow || home.home_general.is_villa || home.home_general.is_duplex) {
            return true
        }
    }

    return false
}

export function otherHomeDetails(home: Home, lang: 'es' | 'en'): string {
    if (lang === 'en') {
        return home.other_details.other_details_english ?? ''
    } else {
        return home.other_details.other_details_spanish ?? ''
    }
}

export function showMetersBuiltInSummary(home: Home): boolean {
    if (showMetersPlotInSummary(home)) {
        return false
    } else if (home.home_general.size_meters_built != null) {
        return true
    }

    return false
}

export function showFloorInSummary(home: Home): boolean {
    return home.home_general.is_apartment
}

export function localizedHomeAmenities(home: Home): string {
    let texts = []

    if (home.extras_and_amenities.num_swimming_pools && home.extras_and_amenities.num_swimming_pools > 0) {
        texts.push(`${home.extras_and_amenities.num_swimming_pools} {${home.extras_and_amenities.num_swimming_pools > 1 ? 'property.label.home_amenities.pools_plural' : 'property.label.home_amenities.pools_singular'}}`)
    }

    if (home.extras_and_amenities.num_gardens && home.extras_and_amenities.num_gardens > 0) {
        texts.push(`${home.extras_and_amenities.num_gardens} {${home.extras_and_amenities.num_gardens > 1 ? 'property.label.home_amenities.gardens_plural' : 'property.label.home_amenities.gardens_singular'}}`)
    }

    if ((home.extras_and_amenities.num_normal_terraces && home.extras_and_amenities.num_normal_terraces > 0) || (home.extras_and_amenities.num_ample_terraces && home.extras_and_amenities.num_ample_terraces > 0)) {
        let terraces = []

        if (home.extras_and_amenities.num_normal_terraces && home.extras_and_amenities.num_normal_terraces > 0) {
            terraces.push(`${home.extras_and_amenities.num_normal_terraces} {${home.extras_and_amenities.num_normal_terraces > 1 ? 'property.label.home_amenities.terraces_plural' : 'property.label.home_amenities.terraces_singular'}}`)
        }

        if (home.extras_and_amenities.num_ample_terraces && home.extras_and_amenities.num_ample_terraces > 0) {
            terraces.push(`${home.extras_and_amenities.num_ample_terraces} {${home.extras_and_amenities.num_ample_terraces > 1 ? 'property.label.home_amenities.ample_terraces_plural' : 'property.label.home_amenities.ample_terraces_singular'}}`)
        }

        let terraceCountText = terraces.join(' {prep.and} ')
        let terrraceSize = home.extras_and_amenities.meters_terrace > 0 ? ` {prep.of} ${home.extras_and_amenities.meters_terrace}m²` : ''

        texts.push(terraceCountText + terrraceSize)
    }

    if (home.extras_and_amenities.num_solariums && home.extras_and_amenities.num_solariums > 0) {
        let solariumCountText = `${home.extras_and_amenities.num_solariums} {${home.extras_and_amenities.num_solariums > 1 ? 'property.label.home_amenities.solariums_plural' : 'property.label.home_amenities.solariums_singular'}}`
        let solariumSize = home.extras_and_amenities.meters_solarium > 0 ? ` {prep.of} ${home.extras_and_amenities.meters_solarium}m²` : ''

        texts.push(solariumCountText + solariumSize)
    }

    if (home.extras_and_amenities.has_patio) {
        texts.push(`{property.label.home_amenities.patio}`)
    }

    if (home.extras_and_amenities.has_kids_play_park) {
        texts.push(`{property.label.home_amenities.kids_play_park}`)
    }

    if (home.extras_and_amenities.has_ventilation_fan) {
        texts.push(`{property.label.home_amenities.ventilation_fan}`)
    }

    if (home.extras_and_amenities.has_tennis_court) {
        texts.push(`{property.label.home_amenities.tennis_court}`)
    }

    if (home.extras_and_amenities.has_padel_court) {
        texts.push(`{property.label.home_amenities.padel_court}`)
    }

    if (home.extras_and_amenities.has_barbequeue) {
        texts.push(`{property.label.home_amenities.barbequeue}`)
    }

    if (home.extras_and_amenities.has_deck_chairs) {
        texts.push(`{property.label.home_amenities.deck_chairs}`)
    }

    if (home.extras_and_amenities.has_porch) {
        texts.push(`{property.label.home_amenities.porch}`)
    }

    if (home.extras_and_amenities.has_storage) {
        texts.push(`{property.label.home_amenities.storage}`)
    }

    if (home.extras_and_amenities.has_terrace_awning) {
        texts.push(`{property.label.home_amenities.terrace_awning}`)
    }

    if (home.extras_and_amenities.has_terrace_furniture) {
        texts.push(`{property.label.home_amenities.terrace_furniture}`)
    }

    return texts.join(', ') + '.'
}

export function localizedBedroomNo(home: Home): string {
    switch (home.home_general.bedrooms) {
        case HomeBedrooms.Studio: return "{property.label.home_bedrooms.studio}";
        case HomeBedrooms.OneBedroom: return "1";
        case HomeBedrooms.TwoBedrooms: return "2";
        case HomeBedrooms.ThreeBedrooms: return "3";
        case HomeBedrooms.FourBedrooms: return "4";
        case HomeBedrooms.FiveBedrooms: return "5";
        case HomeBedrooms.SixOrMoreBedrooms: return "6+";
    }
}

export function localizedBedroomName(home: Home): string {
    switch (home.home_general.bedrooms) {
        case HomeBedrooms.Studio: return "{property.label.home_bedrooms.studio}";
        case HomeBedrooms.OneBedroom: return "{property.label.home_bedrooms.one_bedroom}";
        case HomeBedrooms.TwoBedrooms: return "{property.label.home_bedrooms.two_bedrooms}";
        case HomeBedrooms.ThreeBedrooms: return "{property.label.home_bedrooms.three_bedrooms}";
        case HomeBedrooms.FourBedrooms: return "{property.label.home_bedrooms.four_bedrooms}";
        case HomeBedrooms.FiveBedrooms: return "{property.label.home_bedrooms.five_bedrooms}";
        case HomeBedrooms.SixOrMoreBedrooms: return "{property.label.home_bedrooms.six_or_more_bedrooms}";
    }
}

export function localizedFloor(home: Home): string {
    switch (home.home_general.floor) {
        case HomeFloors.BasementSotano: return "{property.label.home_floor.BasementSotano}";
        case HomeFloors.SemiBasementSemiSotano: return "{property.label.home_floor.SemiBasementSemiSotano}";
        case HomeFloors.GroundFloorPlantaBaja: return "{property.label.home_floor.GroundFloorPlantaBaja}";
        case HomeFloors.MezzanineEntresuelo: return "{property.label.home_floor.MezzanineEntresuelo}";
        case HomeFloors.First: return "{property.label.home_floor.First}";
        case HomeFloors.Second: return "{property.label.home_floor.Second}";
        case HomeFloors.Third: return "{property.label.home_floor.Third}";
        case HomeFloors.Fourth: return "{property.label.home_floor.Fourth}";
        case HomeFloors.Fifth: return "{property.label.home_floor.Fifth}";
        case HomeFloors.Sixth: return "{property.label.home_floor.Sixth}";
        case HomeFloors.Seventh: return "{property.label.home_floor.Seventh}";
        case HomeFloors.Eighth: return "{property.label.home_floor.Eighth}";
        case HomeFloors.Ninth: return "{property.label.home_floor.Ninth}";
        case HomeFloors.Tenth: return "{property.label.home_floor.Tenth}";
        case HomeFloors.Eleventh: return "{property.label.home_floor.Eleventh}";
        case HomeFloors.Twelfth: return "{property.label.home_floor.Twelfth}";
        case HomeFloors.Thirteenth: return "{property.label.home_floor.Thirteenth}";
        case HomeFloors.Fourteenth: return "{property.label.home_floor.Fourteenth}";
        case HomeFloors.Fifteenth: return "{property.label.home_floor.Fifteenth}";
        case HomeFloors.Sixteenth: return "{property.label.home_floor.Sixteenth}";
        case HomeFloors.Seventeenth: return "{property.label.home_floor.Seventeenth}";
        case HomeFloors.Eighteenth: return "{property.label.home_floor.Eighteenth}";
        case HomeFloors.Nineteenth: return "{property.label.home_floor.Nineteenth}";
        case HomeFloors.Twentieth: return "{property.label.home_floor.Twentieth}";
    }
}

export function homeTypeAndSubtype(home: Home): string[] {

    let propertySubtype = SearchPropertySubtype.All

    switch (home.home_general.bedrooms) {
        case HomeBedrooms.Studio: {
            propertySubtype = SearchPropertySubtype.HomeStudio
            break
        }
        case HomeBedrooms.OneBedroom: {
            propertySubtype = SearchPropertySubtype.HomeOneBedroom
            break
        }
        case HomeBedrooms.TwoBedrooms: {
            propertySubtype = SearchPropertySubtype.HomeTwoBedroom
            break
        }
        case HomeBedrooms.ThreeBedrooms: {
            propertySubtype = SearchPropertySubtype.HomeThreeBedroom
            break
        }
        case HomeBedrooms.FourBedrooms:
        case HomeBedrooms.FiveBedrooms:
        case HomeBedrooms.SixOrMoreBedrooms: {
            propertySubtype = SearchPropertySubtype.HomeFourPlusBedroom
            break
        }
    }

    return [SearchPropertyType.Home, propertySubtype]
}