import { GoogleMap, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { MapCoordinates } from '../../models/shared/MapCoordinates';
import './Map.scss';
import MapMarker, { MapMarkerProps } from './MapMarker';

const MapAPIKey = 'AIzaSyAwPDaQXFTvScD9MO_aOavmO7guO3jWWkM'

const MapStyleAry: google.maps.MapTypeStyle[] = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6195a0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6195a0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#e6f3d6"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f4d2c5"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f4f4f4"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f4f4f4"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#787878"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#bbe6ed"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#bbe6ed"
            }
        ]
    }
]

const groupBy = function <T>(xs: T[], key: (val: T) => string) {
    return xs.reduce(function (rv: { [key: string]: T[] }, x) {
        (rv[key(x)] = rv[key(x)] || []).push(x);
        return rv;
    }, {});
};

interface MapProps {
    center: MapCoordinates
    markers: MapMarkerProps[]
    onMapMoved: (coords: MapCoordinates) => void
    adjustToMarkers?: boolean
}

function Map(props: MapProps) {

    const [mapElem, setMapElem] = useState<any | null>(null)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: MapAPIKey
    })

    const markerClusters = groupBy(props.markers.filter(m => m.location.latitude != null && m.location.longitude != null), marker => {
        return `${marker.type}-${marker.subtype ?? 'n'}`
    })

    useEffect(() => {
        if (props.adjustToMarkers && mapElem != null) {
            let bounds = new google.maps.LatLngBounds()
            for (let marker of props.markers) {
                if (marker.isPrimary) {
                    if (marker.location.latitude != null && marker.location.longitude != null) {
                        bounds.extend(new google.maps.LatLng(marker.location.latitude, marker.location.longitude))
                    }
                }
                mapElem.fitBounds(bounds)
            }
        }
    }, [props.adjustToMarkers, props.markers, mapElem])


    function mapMoved() {
        if (mapElem != null) {
            props.onMapMoved({ latitude: mapElem.getCenter().lat(), longitude: mapElem.getCenter().lng(), zoom: mapElem.getZoom() })
        } else {
            console.log('Map is null')
        }
    }

    return <div className='Map w-full h-full rounded overflow-hidden'>
        {isLoaded &&
            <GoogleMap
                options={{ styles: MapStyleAry }}
                mapContainerClassName='Map w-full h-full'
                center={{ lat: props.center.latitude ?? 0, lng: props.center.longitude ?? 0 }}
                zoom={props.center.zoom ?? 15}
                onProjectionChanged={() => { }}
                onZoomChanged={mapMoved}
                onCenterChanged={mapMoved}
                onLoad={map => {
                    setMapElem(map)
                }}
            >

                {props.markers.map((m, idx) => {
                    return <MapMarker key={idx} type={m.type} subtype={m.subtype} title={m.title} location={m.location} isPrimary={m.isPrimary} />

                })}

                {/* {Object.entries(markerClusters).map(cluster => {
                    return (
                        <MarkerClusterer clusterClass={`cluster cluster-${cluster[0]}`} averageCenter={true} minimumClusterSize={3} key={cluster[0]} gridSize={12}>
                            {(clusterer) =>
                                cluster[1].map((elem, idx) => {
                                    return <MapMarker key={idx} type={elem.type} subtype={elem.subtype} title={elem.title} location={elem.location} isPrimary={elem.isPrimary} clusterer={clusterer} />
                                })
                            }
                        </MarkerClusterer>
                    )
                })} */}

            </GoogleMap>
        }

    </div>;
}

export default Map;
