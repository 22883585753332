import { PointOfInterest } from "../../models/map-data/PointOfInterest";
import { Home } from "../../models/properties/Home";
import { Commercial } from "../../models/properties/Commercial";
import { Parking } from "../../models/properties/Parking";
import { Plot } from "../../models/properties/Plot";
import { PropertyListing } from "../../models/list-data/PropertyListing";
import { Season } from '../../models/shared/Season'

export class ModelData {

    public seasons?: Season[]

    public mapItems: {
        pointsOfInterest?: PointOfInterest[]
    }

    public properties: {
        homes?: Home[],
        commercials?: Commercial[],
        parkings?: Parking[],
        plots?: Plot[]
    }

    constructor(
        pointsOfInterest?: PointOfInterest[],
        homes?: Home[],
        commercials?: Commercial[],
        parkings?: Parking[],
        plots?: Plot[],
        seasons?: Season[]) {

        this.seasons = seasons
        this.mapItems = { pointsOfInterest }
        this.properties = {
            homes: homes,
            commercials: commercials,
            parkings: parkings,
            plots: plots
        }
    }

    settingPointsOfInterest(pointsOfInterest: PointOfInterest[] | null): ModelData {
        if (pointsOfInterest != null) {
            return new ModelData(
                pointsOfInterest, 
                this.properties.homes, 
                this.properties.commercials, 
                this.properties.parkings, 
                this.properties.plots,
                this.seasons)
        }
        return this.clone()
    }

    settingHomes(homes: Home[] | null): ModelData {
        if (homes != null) {
            return new ModelData(
                this.mapItems.pointsOfInterest, 
                homes, 
                this.properties.commercials, 
                this.properties.parkings, 
                this.properties.plots,
                this.seasons)
        }
        return this.clone()
    }

    settingCommercials(commercials: Commercial[] | null): ModelData {
        if (commercials != null) {
            return new ModelData(
                this.mapItems.pointsOfInterest, 
                this.properties.homes, 
                commercials, 
                this.properties.parkings, 
                this.properties.plots,
                this.seasons)
        }
        return this.clone()
    }

    settingParkings(parkings: Parking[] | null): ModelData {
        if (parkings != null) {
            return new ModelData(
                this.mapItems.pointsOfInterest, 
                this.properties.homes, 
                this.properties.commercials, 
                parkings, 
                this.properties.plots,
                this.seasons)
        }
        return this.clone()
    }

    settingPlots(plots: Plot[] | null): ModelData {
        if (plots != null) {
            return new ModelData(
                this.mapItems.pointsOfInterest, 
                this.properties.homes, 
                this.properties.commercials, 
                this.properties.parkings, 
                plots,
                this.seasons)
        }
        return this.clone()
    }

    setttingSeasons(seasons: Season[] | null): ModelData {
        if (seasons != null) {
            return new ModelData(
                this.mapItems.pointsOfInterest, 
                this.properties.homes, 
                this.properties.commercials, 
                this.properties.parkings, 
                this.properties.plots,
                seasons)
        }
        return this.clone()
    }


    findListing(refNumber: string): PropertyListing | null {
        if (this.properties.homes != null) {
            for (let home of this.properties.homes) {
                if (home.property.ref_number.toString() === refNumber) {
                    return new PropertyListing(null, home, null, null)
                }
            }
        }

        if (this.properties.commercials != null) {
            for (let comm of this.properties.commercials) {
                if (comm.property.ref_number == refNumber) {
                    return new PropertyListing(comm, null, null, null)
                }
            }
        }

        return null
    }

    allListings(): PropertyListing[] {
        let listings: PropertyListing[] = []

        if (this.properties.homes) {
            listings = listings.concat(this.properties.homes.map(h => {return PropertyListing.FromHome(h)}))
        }

        if (this.properties.commercials) {
            listings = listings.concat(this.properties.commercials.map(c => {return PropertyListing.FromCommercial(c)}))
        }
        
        return listings
    }

    findSeason(): Season | null {
        const year = 2021
        
        if (this.seasons != null) {
            return this.seasons.filter(s => {return s.year === year})[0] ?? null
        }

        return null
    }

    clone(): ModelData {
        return new ModelData(
            this.mapItems.pointsOfInterest, 
            this.properties.homes, 
            this.properties.commercials, 
            this.properties.parkings, 
            this.properties.plots,
            this.seasons)
    }

    static default(): ModelData {
        return new ModelData()
    }
}