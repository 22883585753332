import React, { useContext } from 'react';
import { LocalizationContext } from '../../App';

import ConditionsLinkEn from '../../assets/documents/Rental_Conditions_EN.pdf';
import ConditionsLinkEs from '../../assets/documents/Condiciones_de_Alquiler_ES.pdf';
import ConditionsImgEn from '../../assets/images/conditions/conditions-en.png';
import ConditionsImgEs from '../../assets/images/conditions/conditions-es.png';
import './RentalConditions.scss'

interface ConditionsLinkProps {
    link: string
    image: string
    title: string
}

function ConditionsLink(props: ConditionsLinkProps) {
    return (
        <div className='ConditionsLink p-4 bg-gray-50 rounded border-t-4 border-gray-500 shadow m-4'>
            <a href={props.link}>
                <img className="max-w-xs shadow" src={props.image} alt='Rental Conditions Document'/>
                <p className='pt-2 text-md'>{props.title}</p>
            </a>
        </div>
    )
}



function RentalConditions() {
    const loc = useContext(LocalizationContext).localizer

    return (
        <div className='RentalConditions'>
            <h1 className='text-3xl md:text-5xl mb-2 mt-2 leading-tight font-semibold font-heading cap border-b pb-4'>{loc.k('navigation.rental-conditions')}</h1>
            <div className='flex flex-col md:flex-row'>
                <ConditionsLink link={ConditionsLinkEs} image={ConditionsImgEs} title='Condiciones de Alquiler - Español' />
                <ConditionsLink link={ConditionsLinkEn} image={ConditionsImgEn} title='Rental Conditions - English' />
            </div>
        </div>
    );
}

export default RentalConditions;
